import { message } from "antd";

import { axiosAuthGet, axiosAuthPost, fetchAuthPost } from "modules/Axios";
import { declarationConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getDeclarationList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DECLARATION_LIST_INITIATED });
    let response = await axiosAuthGet(declarationConst.GET_DECLARATION_LIST);
    if (response?.success) await dispatch({ type: actions.GET_DECLARATION_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DECLARATION_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DECLARATION_LIST_ERROR, error: "Network Error" });
  }
};

export const getDeclarationById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DECLARATION_BY_ID_INITIATED });
    let response = await axiosAuthGet(declarationConst.GET_DECLARATION_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_DECLARATION_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DECLARATION_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DECLARATION_BY_ID_ERROR, error: "Network Error" });
  }
};

export const getDeclarationByUId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DECLARATION_BY_UID_INITIATED });
    let response = await axiosAuthGet(declarationConst.GET_DECLARATION_BY_UID + id);
    if (response?.success) await dispatch({ type: actions.GET_DECLARATION_BY_UID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DECLARATION_BY_UID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DECLARATION_BY_UID_ERROR, error: "Network Error" });
  }
};

export const addDeclaration = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_DECLARATION_INITIATED });
    let res = await fetchAuthPost(payload.data); if (res?.success) {
      let response = await axiosAuthPost(declarationConst.ADD_DECLARATION, payload.val);
      if (response?.success) {
        await dispatch({ type: actions.ADD_DECLARATION_SUCCESS, payload: response });
        await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.val.uid, text: "Declaration added" });
      }
      else dispatch({ type: actions.ADD_DECLARATION_ERROR, error: response?.message });
    } else dispatch({ type: actions.ADD_DECLARATION_ERROR, error: res?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_DECLARATION_ERROR, error: "Network Error" });
  }
};

export const updateDeclaration = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_DECLARATION_INITIATED });
    let response = await axiosAuthPost(declarationConst.UPDATE_DECLARATION + payload.dmid, payload);
    if (response?.success) await dispatch({ type: actions.UPDATE_DECLARATION_SUCCESS, payload: response });
    else dispatch({ type: actions.UPDATE_DECLARATION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_DECLARATION_ERROR, error: "Network Error" });
  }
};

export const deleteDeclaration = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_DECLARATION_INITIATED });
    let response = await axiosAuthGet(declarationConst.DELETE_DECLARATION + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "Declaration deleted" });
      await dispatch({ type: actions.DELETE_DECLARATION_SUCCESS, payload: response });
      message.success(response?.message)
    } else dispatch({ type: actions.DELETE_DECLARATION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_DECLARATION_ERROR, error: "Network Error" });
  }
};
