export const getAuthToken = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.token) return auth.token;
    return false;
  }
  return false;
};

export const getAuthUserName = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.userName) return auth.userName;
    return false;
  }
  return false;
};
export const getAuthUserID = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.userid) return auth.userid;
    return false;
  }
  return false;
};
export const getAuthRole = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.uid) return auth.uid;
    return false;
  }
  return false;
};
export const getAuthName = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.name) return auth.name;
    return false;
  }
  return false;
};