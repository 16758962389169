import React, { Component } from "react";
import { Modal, Tooltip } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { QuestionCircleOutlined, LogoutOutlined } from "@ant-design/icons";

import { PageConst } from "./constant";
import { StyleComponent } from "./style";
import { logout } from "redux/login/actions";
import { menuCol } from "redux/app/actions";
import { RemoveConst } from "App/AppConstant";
import { RenderDrop } from "components/Form";
import { getAuthName } from "modules/helper";
const { confirm } = Modal;
var userName = getAuthName();
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "" };
  }
  componentDidMount() {
    userName = userName ? userName : getAuthName();
  }
  logoutWarn = () => {
    try {
      this.setState({ hidden: false });
      confirm({
        title: RemoveConst.title,
        icon: <QuestionCircleOutlined />,
        content: RemoveConst.logMessage,
        okText: RemoveConst.yes,
        okType: "danger",
        cancelText: RemoveConst.no,
        getContainer: () => document.getElementById("menu-form"),
        onOk: () => {
          this.props.logout();
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleSearch = (e) => this.setState({ search: e.target.value });
  render() {
    const { collapsed, history } = this.props;
    return (
      <StyleComponent id="menu-form">
        <div
          className="hamburger"
          onClick={() => this.props.menuCol(!collapsed)}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
        {/* <div className="btnDiv anime"><Button className="flex" onClick={() => this.props.history.push(link)}><PlusOutlined /><h3 className="txt">{PageConst.an}</h3></Button>
        </div><div className="btn1Div"><Input name="userId"value={search}placeholder={PageConst.search}handleChange={this.handleSearch}max={255}/></div> */}
        <div className="ml-auto flex">
          <RenderDrop
            item={
              <div className="pointer">
                <i className={"fas fa-user"}></i>
                <span className="uDiv">{userName}</span>
              </div>
            }
            data={[
              <div
                onClick={() =>
                  history.push(
                    localStorage.auth && JSON.parse(localStorage.auth).isDP
                      ? "/profile"
                      : "/userProfile"
                  )
                }
              >
                <div className="title">{PageConst.pf}</div>
              </div>,
              <div onClick={() => history.push("/change-password")}>
                <div className="title">{PageConst.cp}</div>
              </div>,
              <div onClick={() => this.logoutWarn()}>
                <div className="title">{PageConst.lg}</div>
              </div>,
            ]}
          />
          <div className="log">
            <Tooltip title={"Log-out"} className="title">
              <LogoutOutlined onClick={() => this.logoutWarn()} />
            </Tooltip>
          </div>
        </div>
      </StyleComponent>
    );
  }
}
const mapStateToProps = (state) => ({
  collapsed: state.app.collapsed,
});
const mapDispatchToProps = (dispatch) => ({
  logout: (payload) => dispatch(logout(payload)),
  menuCol: (payload) => dispatch(menuCol(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
