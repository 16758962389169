const PageConst = {
  title: " Trading Window Closure",
  person: "Persons ",
  rs: "Reason for Closure*",
  from: "From Date*",
  to: "To Date*",
  mDate: "To date is less than From date",
  ce: "Can't send email",
  frd: "Financial Result Delared",
  upDate: "Selected date is less than UPSI Date",
  yes: "Yes",
  no: "No",
  dp: "(Designated Person)",
  cp: "(Connected Person)",
};
export { PageConst };
