const DecConst = { dec: "Reports" };

const TabsName = [
  "Designated Persons Report",
  "Connected Person Report",
  "Deviation Report",
  "Holding Statement",
  "UPSI Statement",
  "Audit Logs",
  "Mail / SMS Report",
];
const DevConst = [
  { id: 1, value: "Contra Trades" },
  { id: 2, value: "Pre Clearance-Violation" },
  { id: 3, value: "Variation" }, //"Intimation vs Benpos"
];

const DpCpList = [
  { label: "#", value: "key" },
  { label: "DPID", value: "dpid" },
  { label: "Client Id", value: "clientID" },
  { label: "Name", value: "name" },
  { label: "PAN no.", value: "pan" },
  { label: "Previous Holding As On", value: "date2" },
  { label: "Previous Holding", value: "preHolding" },
  { label: "Buying(+)", value: "buying" },
  { label: "Selling(-)", value: "selling" },
  { label: "Present Holding As On", value: "date1" },
  { label: "Present Holdings", value: "totalShare" },
  { label: "% of equity", value: "equity" },
  { label: "Category", value: "category" },
];
const ContraKeys = [
  { label: "#", value: "key" },
  { label: "Name", value: "name" },
  { label: "PAN no.", value: "pan" },
  { label: "Buy", value: "buy" },
  { label: "Sale", value: "sale" },
  { label: "Quantity", value: "quality" },
  { label: "Category", value: "category" },
  { label: "Date", value: "hdate" },
];
const VariactionKeys = [
  { label: "#", value: "key" },
  { label: "Name", value: "name" },
  { label: "PAN no.", value: "pan" },
  { label: "Pre-Clearence Approval", value: "approval" },
  { label: "Approved Transaction Type", value: "purpose" },
  { label: "Approved Quantity", value: "qty" },
  { label: "Buy", value: "buy" },
  { label: "Sale", value: "sale" },
  { label: "Previous Quantity", value: "prevShare" },
  { label: "Present Holding", value: "totalShare" },
  { label: "Variation Quantity", value: "variation" },
  { label: "PreClearance Start Date", value: "sDate" },
  { label: "PreClearance End Date", value: "eDate" },
  { label: "Transaction Date", value: "hdate" },
]
const HoldingKeys = [
  { label: "#", value: "key" },
  { label: "DPID", value: "dpid" },
  { label: "Client Id", value: "clientID" },
  { label: "Name", value: "name" },
  { label: "PAN no.", value: "pan" },
  { label: "Holding As On", value: "hdate" },
  { label: "Shares", value: "totalShare" },
  { label: "% of equity", value: "equity" },
  { label: "Category", value: "category" },
];
const UPSIKeys = [
  { label: "#", value: "key" },
  { label: "Type of UPSI", value: "type" },
  { label: "Sender", value: "dpName" },
  { label: "Receiver", value: "receiver" },
  { label: "Purpose", value: "purpose" },
  { label: "Mode", value: "mode" },
  { label: "Type", value: "type" },
  { label: "Description", value: "description" },
  { label: "Time Stamp", value: "timeStamp" },
  { label: "UPSI Date", value: "UPSIDate" },
];
const AuditKeys = [
  { label: "#", value: "key" },
  { label: "Log", value: "text" },
  { label: "User", value: "user" },
  { label: "Date", value: "date1" },
];
export { DecConst, TabsName, DevConst, DpCpList, ContraKeys, VariactionKeys, HoldingKeys, UPSIKeys, AuditKeys };
