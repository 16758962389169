import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  auditLogList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_AUDIT_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        auditLogList: action.payload.data,
      };
    case actions.GET_AUDIT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    default:
      return state;
  }
};
