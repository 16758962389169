import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { ButtonConst } from "App/AppConstant";
import { getUserList } from "redux/user/actions";
import { getMasterList } from "redux/master/actions";
import { addConRef, getConRefByUId } from "redux/connRef/actions";
import {
  Label,
  Button,
  Row,
  Col,
  Select,
  LayOut,
  Checkbox,
} from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";
var role = getAuthRole();
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  refName: Yup.string().trim().nullable().required(" "),
  dpId: Yup.string().trim().nullable(),
  reference: Yup.string().trim().nullable(),
});

class Disclosures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      disable: false,
      ulist: [],
      clist: [],
      relList: [],
      typeList: [],
      init: {
        uId: 0,
        dpRelationship: 0,
        dpId: null,
        dpName: "",
        refName: "",
        relation: "Other",
        reference: null,
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      if (match?.params?.id) {
        this.setState({ uid: window.atob(match?.params?.id) });
        await this.props.getConRefByUId(window.atob(match?.params?.id));
      }
      await this.props.getUserList();
      await this.props.getMasterList();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { userList, masterList, isSaved, connRefuData } = this.props;
      if (isSaved !== prevProps.isSaved)
        isSaved && this.props.history.goBack();
      if (connRefuData !== prevProps.connRefuData) {
        let clist = connRefuData?.filter((a) => a.is_deleted === 0);
        this.setState({ clist });
      }
      if (userList !== prevProps.userList) {
        const { clist } = this.state;
        let data = [], rids = [], crlist = [];
        clist?.forEach(r => crlist.push(r.dpId));
        let urlist = userList.filter((a) => a.is_deleted === 0 && a.uid < 68 && !crlist?.includes(a.id));
        urlist?.forEach((a) => {
          if (role === 67 && a.id !== userId) { }
          else {
            a.value = a.surname ? a.name + " " + a.surname : a.name;
            let pos = a.uid === 66 ? " (CO" : a.uid === 67 ? " (DP" : a.uid === 68 ? " (PCS" : " (CP"
            if (data.find(b => b.value === a.value + pos + ")")) {
              if (data.find(b => b.value === a.value + pos + ") 2")) {
                if (data.find(b => b.value === a.value + pos + ") 3"))
                  data.push({ id: a.id, value: a.value + pos + ") 4" });
                else data.push({ id: a.id, value: a.value + pos + ") 3" });
              } else data.push({ id: a.id, value: a.value + pos + ") 2" });
            } else data.push({ id: a.id, value: a.value + pos + ")" });
            rids.push(a.id);
          }
        });
        this.setState({ ulist: data, rids });
      }
      if (prevProps.masterList !== masterList) {
        let cflist = [];
        let ml = masterList.filter((a) => a.typeName === "Reference" && a.is_deleted === 0);
        let ml2 = masterList.filter((a) => a.typeName === "Relationship" && a.is_deleted === 0);
        const { clist } = this.state;
        clist?.forEach(r => cflist.push(r.reference));
        let reflist = ml.filter((a) => a.is_deleted === 0 && !cflist?.includes(a.id));
        reflist?.forEach((a) => (a.value = a.mname));
        ml2?.forEach((a) => (a.value = a.mname));
        this.setState({ typeList: reflist, relList: ml2 });
      }
    } catch (error) {
      console.log(error);
    }
  }

  selectUI = (val, name, setFieldValue, error) => {
    try {
      const { typeList, ulist, relList } = this.state;
      let data = name === "refName" ? typeList : name === "dpName" ? ulist : name === "relation" ? relList : [];
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          defaultValue={val}
          className={error && val === "" ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue(name, value);
            if (name === "dpName") {
              setFieldValue("refName", value);
              setFieldValue("dpId", data.id);
            } else if (name === "refName") setFieldValue("reference", data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  onChange = (setFieldValue) => {
    setFieldValue("refName", "");
    setFieldValue("dpName", "");
    setFieldValue("relation", "");
    setFieldValue("dpId", null);
    setFieldValue("reference", null);
    this.setState({ checked: !this.state.checked });
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { checked, uid } = this.state;
      values.dpRelationship = checked ? 1 : 0;
      values.uId = uid ? uid : userId;
      values.cid = userId;
      values.relation = checked ? values.relation : ""
      if (values.dpId) delete values.refName;
      await this.props.addConRef(values);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { init, disable, checked } = this.state;
    const { loading, loading1, loading2 } = this.props;
    return (
      <Spin spinning={loading || loading1 || loading2} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.title}</h4>
              <Button
                onClick={() => this.props.history.goBack()}
              >
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                        <Label title={PageConst.pct} />
                        <div className="flex">
                          <Checkbox
                            onChange={() => this.onChange(setFieldValue)}
                            checked={checked}
                            text={PageConst.yes}
                          />
                          <Checkbox
                            onChange={() => this.onChange(setFieldValue)}
                            checked={!checked}
                            text={PageConst.no}
                          />
                        </div>
                      </Col>
                      {checked ? (<>
                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                          <Label title={PageConst.dp}
                            className={errors.refName && touched.refName ? "empty" : ""}
                          />
                          {values.dpName === "" &&
                            this.selectUI(
                              values.dpName,
                              "dpName",
                              setFieldValue,
                              errors.refName && touched.refName
                            )}
                          {values.dpName !== "" &&
                            this.selectUI(values.dpName, "dpName", setFieldValue)}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                          <Label title={PageConst.rel} />
                          {values.relation === "" &&
                            this.selectUI(values.relation, "relation", setFieldValue)}
                          {values.relation !== "" &&
                            this.selectUI(values.relation, "relation", setFieldValue)}
                        </Col>
                      </>
                      ) : (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                          <Label
                            title={PageConst.ref}
                            className={
                              errors.refName && touched.refName ? "empty" : ""
                            }
                          />
                          {values.refName === "" &&
                            this.selectUI(
                              values.refName,
                              "refName",
                              setFieldValue,
                              errors.refName && touched.refName
                            )}
                          {values.refName !== "" &&
                            this.selectUI(
                              values.refName,
                              "refName",
                              setFieldValue
                            )}
                        </Col>
                      )}
                    </Row>
                    <div className="btnDiv anime mt-1">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  loading1: state.master.loading,
  loading2: state.connref.loading,
  userList: state.user.userList,
  masterList: state.master.masterList,
  isSaved: state.connref.isSaved,
  connRefuData: state.connref.connRefuData,
});
const mapDispatchToProps = (dispatch) => ({
  addConRef: (payload) => dispatch(addConRef(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  getConRefByUId: (payload) => dispatch(getConRefByUId(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Disclosures)
);
