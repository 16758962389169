export const GET_EMPLOYMENT_LIST_INITIATED = "GET_EMPLOYMENT_LIST_INITIATED";
export const GET_EMPLOYMENT_LIST_SUCCESS = "GET_EMPLOYMENT_LIST_SUCCESS";
export const GET_EMPLOYMENT_LIST_ERROR = "GET_EMPLOYMENT_LIST_ERROR";

export const GET_EMPLOYMENT_BY_ID_INITIATED = "GET_EMPLOYMENT_BY_ID_INITIATED";
export const GET_EMPLOYMENT_BY_ID_SUCCESS = "GET_EMPLOYMENT_BY_ID_SUCCESS";
export const GET_EMPLOYMENT_BY_ID_ERROR = "GET_EMPLOYMENT_BY_ID_ERROR";

export const ADD_EMPLOYMENT_INITIATED = "ADD_EMPLOYMENT_INITIATED";
export const ADD_EMPLOYMENT_SUCCESS = "ADD_EMPLOYMENT_SUCCESS";
export const ADD_EMPLOYMENT_ERROR = "ADD_EMPLOYMENT_ERROR";

export const UPDATE_EMPLOYMENT_INITIATED = "UPDATE_EMPLOYMENT_INITIATED";
export const UPDATE_EMPLOYMENT_SUCCESS = "UPDATE_EMPLOYMENT_SUCCESS";
export const UPDATE_EMPLOYMENT_ERROR = "UPDATE_EMPLOYMENT_ERROR";

export const DELETE_EMPLOYMENT_INITIATED = "DELETE_EMPLOYMENT_INITIATED";
export const DELETE_EMPLOYMENT_SUCCESS = "DELETE_EMPLOYMENT_SUCCESS";
export const DELETE_EMPLOYMENT_ERROR = "DELETE_EMPLOYMENT_ERROR";