import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  educationData: {},
  educationList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_EDUCATION_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_EDUCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        educationList: action.payload.data,
      };
    case actions.GET_EDUCATION_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_EDUCATION_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_EDUCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        educationData: action.payload.data,
      };
    case actions.GET_EDUCATION_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };

    case actions.ADD_EDUCATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_EDUCATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_EDUCATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_EDUCATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_EDUCATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_EDUCATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
