import React, { Component } from "react";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { TradingConst } from "./constant";
import { sendmail } from "redux/smtp/actions";
import { getUserList } from "redux/user/actions";
import { getConRefList } from "redux/connRef/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { getTemplateInfoList } from "redux/templates/actions";
import { Button, Table, LayOut, Input } from "components/Form";
import {
  getTradingList,
  deleteTrading,
  updateTrading,
} from "redux/trading/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class ClearanceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clist: [],
      usrlist: [],
      uilst: [],
      tempMail: [],
      search: "",
    };
  }
  async componentDidMount() {
    try {
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      await this.props.getConRefList();
      await this.props.getTradingList();
      await this.props.getUserList();
      await this.props.getTemplateInfoList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { tradingList, connRefList, templateList, userList } = this.props;
      if (prevProps.userList !== userList) this.setState({ uilst: userList });
      if (prevProps.tradingList !== tradingList) {
        const { usrlist } = this.state;
        let tList = tradingList?.filter((a) => a.is_deleted === 0);
        let tList2 = [];
        if (role === 67) {
          usrlist.push(userId.toString());
          usrlist?.forEach((s) => {
            let cl = tList?.filter(
              (a) => a.connectedID?.toString() === s || a.uid?.toString() === s
            );
            cl?.forEach((f) => {
              if (tList2?.findIndex((d) => d.id === f.id) === -1)
                tList2.push(f);
            });
          });
          tList = [];
        }
        let allcl = tList.concat(tList2);
        allcl?.forEach((f) => (f.pid = "PreClearance-" + f.id));
        let sortData = allcl?.sort((a, b) => b.id - a.id);
        this.setState({ clist: sortData });
      }
      if (connRefList !== prevProps.connRefList) {
        let list = [];
        connRefList?.filter(
          (a) =>
            a.is_deleted === 0 &&
            a.dpId === userId &&
            list.push(a.uId?.toString())
        );
        this.setState({ usrlist: list });
      }
      if (templateList !== prevProps.templateList)
        this.setState({ tempMail: templateList });
    } catch (error) {
      console.log(error);
    }
  }
  setclr = async (id, b) => {
    try {
      await this.props.updateTrading({
        id: id,
        uid: userId,
        type: b ? "Approved" : "Rejected",
      });
      await this.props.getTradingList();
      if (b) this.sendToUser(id);
    } catch (error) {
      console.log(error);
    }
  };
  sendToUser = (a) => {
    try {
      const { clist, uilst } = this.state;
      let cl = clist?.find((d) => d.id === a);
      let user = uilst?.find((x) => x.id === cl?.connectedID);
      if (user) this.sendMail(user.email);
    } catch (error) {
      console.log(error);
    }
  };
  sendMail = async (toData) => {
    try {
      const { tempMail } = this.state;
      if (toData && tempMail[0]) {
        let temp = tempMail[0]?.template;
        let mailData = { to: toData, subject: tempMail[0]?.name, html: temp };
        let postData = {
          uid: userId,
          mail: mailData,
          text: { type: tempMail[0]?.name, send: toData },
        };
        await this.props.sendmail(postData);
      } else message.error(TradingConst.ce);
    } catch (error) {
      console.log(error);
    }
  };
  removeData = async (id) => {
    try {
      await this.props.deleteTrading({ id: id, uid: userId });
      await this.props.getTradingList();
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { clist, search } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{TradingConst.trading}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role === 67 && (
                  <Button
                    onClick={() => this.props.history.push("/add-trading")}
                  >
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display trad">
              <Table
                search={search}
                data={clist}
                type="trading"
                tab="Trading"
                editCol={(id) => this.props.history.push("/edit-trading/" + id)}
                deleteCol={this.removeData}
                setclr={this.setclr}
                sendMail={this.sendToUser}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.trading.loading,
  tradingList: state.trading.tradingList,
  connRefList: state.connref.connRefList,
  userList: state.user.userList,
  templateList: state.template.templateList,
});
const mapDispatchToProps = (dispatch) => ({
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  deleteTrading: (payload) => dispatch(deleteTrading(payload)),
  updateTrading: (payload) => dispatch(updateTrading(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  sendmail: (payload) => dispatch(sendmail(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClearanceRequest)
);
