import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  docData: {},
  docList: [],
  docuData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DECLARATION_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DECLARATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        docList: action.payload.data,
      };
    case actions.GET_DECLARATION_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DECLARATION_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DECLARATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        docData: action.payload.data,
      };
    case actions.GET_DECLARATION_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DECLARATION_BY_UID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DECLARATION_BY_UID_SUCCESS:
      return {
        ...state,
        loading: false,
        docuData: action.payload.data,
      };
    case actions.GET_DECLARATION_BY_UID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.ADD_DECLARATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_DECLARATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_DECLARATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_DECLARATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_DECLARATION_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_DECLARATION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
