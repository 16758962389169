import styled from "styled-components";
import { size } from "App/device";

const ChangePWDStyle = styled.div`
  .form_div {
    margin: 5% 30%;
    max-width: 30em;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
    padding: 2em 3em;
    @media ${size["tablet-md-max"]} {
    margin: 5% 15%;
  }
    @media ${size["tablet-sm-max"]} {
      margin: 3%;
    }
    .field {
      margin-top: 1em;
    }
  }
`;

export { ChangePWDStyle };
