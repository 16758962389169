const DashboardConst = {
  desc1: "Total Number of UPSI",
  desc2: "Total Number of Trading Requests",
  desc3: "Total Number of Designated Persons",
  desc4: "Total Number of Connected Persons",
  num1: "58",
  num2: "60",
  num3: "88",
  num4: "65",
  head1: "Month-on-Month Request",
  head2: "Closing Window",
  head3: "UPSI Information List",
  head4: "Pending Trading Request",
};
const MonthConst = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export { DashboardConst, MonthConst };

