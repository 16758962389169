import { message } from "antd";

import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { SMTPConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getSMTPList = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_SMTP_LIST_INITIATED });
    let response = await axiosAuthGet(SMTPConst.GET_SMTP_LIST + id);
    if (response?.success) await dispatch({ type: actions.GET_SMTP_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_SMTP_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_SMTP_LIST_ERROR, error: "Network Error" });
  }
};

export const getSMTPById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_SMTP_BY_ID_INITIATED });
    let response = await axiosAuthGet(SMTPConst.GET_SMTP_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_SMTP_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_SMTP_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_SMTP_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addSMTP = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_SMTP_INITIATED });
    let response = await axiosAuthPost(SMTPConst.ADD_SMTP, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: "SMTP added" });
      await dispatch({ type: actions.ADD_SMTP_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_SMTP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_SMTP_ERROR, error: "Network Error" });
  }
};

export const updateSMTP = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_SMTP_INITIATED });
    let response = await axiosAuthPost(SMTPConst.UPDATE_SMTP + payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "SMTP updated" });
      await dispatch({ type: actions.UPDATE_SMTP_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.UPDATE_SMTP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_SMTP_ERROR, error: "Network Error" });
  }
};

export const updateActiveSMTP = () => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_ACTIVE_SMTP_INITIATED });
    let response = await axiosAuthPost(SMTPConst.UPDATE_ACTIVE_SMTP, {});
    if (response?.success) await dispatch({ type: actions.UPDATE_ACTIVE_SMTP_SUCCESS, payload: response });
    else dispatch({ type: actions.UPDATE_ACTIVE_SMTP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_ACTIVE_SMTP_ERROR, error: "Network Error" });
  }
};

export const deleteSMTP = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_SMTP_INITIATED });
    let response = await axiosAuthGet(SMTPConst.DELETE_SMTP + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "SMTP deleted" });
      await dispatch({ type: actions.DELETE_SMTP_SUCCESS, payload: response });
      message.success(response?.message)
    } else dispatch({ type: actions.DELETE_SMTP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_SMTP_ERROR, error: "Network Error" });
  }
};

export const sendmail = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.SEND_MAIL_INITIATED });
    let response = await axiosAuthPost(SMTPConst.SEND_MAIL, payload.mail);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: "Email Sent", otherText: JSON.stringify(payload.text) });
      await dispatch({ type: actions.SEND_MAIL_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.SEND_MAIL_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.SEND_MAIL_ERROR, error: "Network Error" });
  }
};