import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { configVar } from "modules/config";
import { ButtonConst, appConst } from "App/AppConstant";
import { PageConst } from "container/AddCompany/constant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Button, Row, Col, LayOut, Label } from "components/Form";
import { getCompanyList, deleteCompany } from "redux/company/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class Company extends Component {
  constructor(props) {
    super(props);
    this.state = { company: [] };
  }
  async componentDidMount() {
    try {
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getCompanyList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { companyList } = this.props;
      if (companyList !== prevProps.companyList) {
        let company = companyList?.filter((a) => a.is_deleted === 0);
        this.setState({ company });
      }
    } catch (error) {
      console.log(error);
    }
  }
  redirect = (id) => this.props.history.push("/edit-company/" + id);
  removeData = async (id) => {
    try {
      await this.props.deleteCompany({ id: id, uid: userId });
      await this.props.getCompanyList();
    } catch (error) {
      console.log(error);
    }
  };
  lblUI = (txt, txt2, a) => {
    try {
      if (txt?.charAt(txt.length - 1) === "*")
        txt = txt.substring(0, txt.length - 1);
      if (txt2)
        return (
          <Col xs={24} sm={24} md={24} lg={12} xl={a ? a : 8}>
            <Label title={txt + " : "} />
            <h3>{txt2}</h3>
          </Col>
        );
    } catch (error) {
      console.log(error);
    }
  };
  setDate = (date) => {
    try {
      let day = new Date(date).getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date).getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date).getFullYear();
      return date ? day + "-" + month + "-" + year : "";
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { company } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.cl}</h4>
              {company.length === 0 && role !== 68 && (
                <Button onClick={() => this.props.history.push("/add-company")}>
                  {ButtonConst.add}
                </Button>
              )}
            </div>
            <div className="display">
              {company?.map((a, i) => (
                <Row gutter={40} key={i}>
                  {this.lblUI(PageConst.cnm, a.name)}
                  {this.lblUI(PageConst.di, this.setDate(a.incorporationDate))}
                  {this.lblUI(PageConst.le, a.listedExchange)}
                  {this.lblUI(PageConst.ld, this.setDate(a.listedDate))}
                  {this.lblUI(PageConst.lc, a.listedCode)}
                  {this.lblUI(PageConst.ts, a.totalshare)}
                  {this.lblUI(PageConst.cin, a.cin)}
                  {this.lblUI(PageConst.pan, a.pan)}
                  {this.lblUI(PageConst.isin, a.insi)}
                  {this.lblUI(PageConst.gst, a.gst)}
                  {this.lblUI(PageConst.city, a.city)}
                  {this.lblUI(PageConst.state, a.state)}
                  {this.lblUI(PageConst.pin, a.pin)}
                  {this.lblUI(PageConst.add, a.regAddress, 16)}
                  {this.lblUI(
                    PageConst.symbol,
                    a.sname?.length > 0 ? (
                      <a
                        href={configVar.BASE_URL + "files/" + a.sname}
                        download={a.sname}
                      >
                        {a.sname}
                      </a>
                    ) : null
                  )}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="btnDiv anime">
                      <Button onClick={() => this.redirect(window.btoa(a.id))}>
                        {ButtonConst.edit}
                      </Button>
                    </div>
                  </Col>
                </Row>
              ))}
              {/* <Table data={company}type="company"tab="Company"editCol={this.redirect}deleteCol={this.removeData}/> */}
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.company.loading,
  companyList: state.company.companyList,
});
const mapDispatchToProps = (dispatch) => ({
  getCompanyList: (payload) => dispatch(getCompanyList(payload)),
  deleteCompany: (payload) => dispatch(deleteCompany(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Company)
);
