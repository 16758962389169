import { message } from "antd";
import { push } from "connected-react-router";

import { axiosPost, axiosGet } from "modules/Axios";
import { SubscriptionConst } from "modules/config";
import * as actions from "./constant";

export const getSubscriptionList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_SUBSCRIPTION_LIST_INITIATED });
    let response = await axiosGet(SubscriptionConst.GET_SUBSCRIPTION_LIST);
    if (response?.success)
      await dispatch({ type: actions.GET_SUBSCRIPTION_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_SUBSCRIPTION_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_SUBSCRIPTION_LIST_ERROR, error: "Network Error" });
  }
};

export const getSubscriptionById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_SUBSCRIPTION_BY_ID_INITIATED });
    let response = await axiosGet(SubscriptionConst.GET_SUBSCRIPTION_BY_ID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_SUBSCRIPTION_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_SUBSCRIPTION_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_SUBSCRIPTION_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addSubscription = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_SUBSCRIPTION_INITIATED });
    let response = await axiosPost(SubscriptionConst.ADD_SUBSCRIPTION, payload);
    if (response?.success) {
      await dispatch({ type: actions.ADD_SUBSCRIPTION_SUCCESS, payload: response });
      dispatch(push("/login"));
    }
    else dispatch({ type: actions.ADD_SUBSCRIPTION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_SUBSCRIPTION_ERROR, error: "Network Error" });
  }
};

export const updateSubscription = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_SUBSCRIPTION_INITIATED });
    let response = await axiosPost(SubscriptionConst.UPDATE_SUBSCRIPTION + payload.val.id, payload.val);
    if (response?.success) {
      await dispatch({ type: actions.UPDATE_SUBSCRIPTION_SUCCESS, payload: response });      
    } else dispatch({ type: actions.UPDATE_SUBSCRIPTION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_SUBSCRIPTION_ERROR, error: "Network Error" });
  }
};

export const deleteSubscription = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_SUBSCRIPTION_INITIATED });
    let response = await axiosGet(SubscriptionConst.DELETE_SUBSCRIPTION + payload.id);
    if (response?.success) {
      await dispatch({ type: actions.DELETE_SUBSCRIPTION_SUCCESS, payload: response });
      message.success(response?.message);
    } else dispatch({ type: actions.DELETE_SUBSCRIPTION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_SUBSCRIPTION_ERROR, error: "Network Error" });
  }
};
