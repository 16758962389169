import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst, elist } from "./constant";
import { ButtonConst, FormValid, panConst } from "App/AppConstant";
import {
  addCompany,
  getCompanyById,
  updateCompany,
} from "redux/company/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  LayOut,
  FileUpload,
  DatePicker,
  Select,
} from "components/Form";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .min(2)
    .max(50)
    .matches(/^[aA-zZ09\s~!@#$%^&*()_+-/.]+$/, FormValid.charValid),
  gst: Yup.string()
    .trim()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[A-Za-z0-9]{1}$/,
      FormValid.gstValid
    ),
  cin: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(
      /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
      FormValid.cinValid
    ),
  pan: Yup.string().trim().required(" ").matches(panConst, FormValid.panValid),
  insi: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^([A-Z]{2})([A-Z0-9]{10})$/, FormValid.isinValid),
  regAddress: Yup.string().trim().nullable().required(" "),
  city: Yup.string()
    .trim()
    .nullable()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  state: Yup.string()
    .trim()
    .nullable()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  pin: Yup.string().nullable().min(6).max(6).required(" "),
  listedCode: Yup.number().nullable().min(100000," ").max(999999," ").required(" ").test("Is positive?", " ", (value) => value > 0),
  totalshare: Yup.string().nullable().required(" "),
  incorporationDate: Yup.date().nullable(),
  listedDate: Yup.date()
    .nullable()
    .when(
      "incorporationDate",
      (incorporationDate, schema) =>
        incorporationDate && schema.min(incorporationDate, PageConst.lfi)
    ),
});

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      disable: false,
      above: false,
      edit: false,
      docByte: "",
      docnm: "",
      ml: [],
      init: {
        name: "",
        pan: "",
        cin: "",
        insi: "",
        incorporationDate: "",
        regAddress: "",
        city: "",
        state: "",
        pin: "",
        symbol: "",
        listedExchange: "",
        listedDate: "",
        listedCode: "",
        totalshare: "",
        gst: "",
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      if (match?.path?.toLowerCase().includes("/edit-company/")) {
        match.params.id &&
          (await this.props.getCompanyById(window.atob(match.params.id)));
        this.setState({ edit: true });
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { companyData } = this.props;
      if (companyData !== prevProps.companyData) {
        let cData = {
          id: companyData.id,
          name: companyData.name,
          pan: companyData.pan?.toUpperCase(),
          cin: companyData.cin?.toUpperCase(),
          insi: companyData.insi?.toUpperCase(),
          incorporationDate: companyData.incorporationDate,
          regAddress: companyData.regAddress,
          city: companyData.city,
          state: companyData.state,
          pin: companyData.pin,
          gst: companyData.gst?.toUpperCase(),
          listedExchange: companyData.listedExchange,
          listedDate: companyData.listedDate,
          listedCode: companyData.listedCode,
          totalshare: companyData.totalshare,
          symbol: companyData.symbol,
          sname: companyData.sname,
        };
        this.setState({ init: cData, docnm: companyData.sname }); //docByte: companyData.symbol,
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, name, setFieldValue, error) => {
    try {
      return (
        <Select
          withID
          name={name}
          data={elist}
          value={val}
          defaultValue={val}
          className={error ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue(name, value);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  seByte = (byteCode, name) =>
    this.setState({ docByte: byteCode, docnm: name });
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { docByte, docnm } = this.state;
      let postData = { val: values }; // values.symbol = docByte;
      values.sname = docnm;
      values.gst = values.gst?.toUpperCase();
      values.pan = values.pan?.toUpperCase();
      values.insi = values.insi?.toUpperCase();
      values.cin = values.cin?.toUpperCase();
      if (docnm !== "") postData.data = docByte;
      values.uid = userId;
      if (!values.id) await this.props.addCompany(postData);
      else await this.props.updateCompany(postData);
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { init, disable, docByte, docnm, edit } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">
                {!edit ? PageConst.title : PageConst.title2}
              </h4>
              <Button onClick={() => this.props.history.push("/company")}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.cnm}
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        <Input
                          name="name"
                          value={values.name}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={255}
                          tabIndex="1"
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.di} />
                        <DatePicker
                          value={values.incorporationDate}
                          handleChange={handleChange("incorporationDate")}
                          tabIndex="2"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.le} />
                        {values.listedExchange === "" &&
                          this.selectUI(
                            values.listedExchange,
                            "listedExchange",
                            setFieldValue,
                            errors.listedExchange && touched.listedExchange
                          )}
                        {values.listedExchange !== "" &&
                          this.selectUI(
                            values.listedExchange,
                            "listedExchange",
                            setFieldValue
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.ld}
                          className={
                            errors.listedDate && touched.listedDate
                              ? "empty"
                              : ""
                          }
                        />
                        <DatePicker
                          value={values.listedDate}
                          handleChange={handleChange("listedDate")}
                          tabIndex="4"
                          className={
                            errors.listedDate && touched.listedDate
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.listedDate && touched.listedDate ? (
                          <div className="form-error">{errors.listedDate}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.lc}
                          className={
                            errors.listedCode && touched.listedCode
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          min="100000"
                          max="999999"
                          name="listedCode"
                          value={values.listedCode}
                          onBlur={handleBlur}                          
                          tabIndex="5"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "listedCode", val)
                          }
                          className={
                            errors.listedCode && touched.listedCode
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.ts}
                          className={
                            errors.totalshare && touched.totalshare
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          name="totalshare"
                          value={values.totalshare}
                          onBlur={handleBlur}
                          
                          tabIndex="5"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "totalshare", val)
                          }
                          className={
                            errors.totalshare && touched.totalshare
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.cin}
                          className={errors.cin && touched.cin ? "empty" : ""}
                        />
                        <Input
                          name="cin"
                          value={values.cin?.toUpperCase()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="6"
                          className={errors.cin && touched.cin ? "empty" : ""}
                        />
                        {errors.cin && touched.cin ? (
                          <div className="form-error">{errors.cin}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pan}
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        <Input
                          name="pan"
                          value={values.pan?.toUpperCase()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="7"
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        {errors.pan && touched.pan ? (
                          <div className="form-error">{errors.pan}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.isin}
                          className={errors.insi && touched.insi ? "empty" : ""}
                        />
                        <Input
                          name="insi"
                          value={values.insi?.toUpperCase()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          className={errors.insi && touched.insi ? "empty" : ""}
                          tabIndex="8"
                        />
                        {errors.insi && touched.insi ? (
                          <div className="form-error">{errors.insi}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.gst}
                          className={errors.gst && touched.gst ? "empty" : ""}
                        />
                        <Input
                          name="gst"
                          value={values.gst?.toUpperCase()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          className={errors.gst && touched.gst ? "empty" : ""}
                          tabIndex="9"
                        />
                        {errors.gst && touched.gst ? (
                          <div className="form-error">{errors.gst}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.city}
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                        <Input
                          maxLength={50}
                          name="city"
                          value={values.city}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="10"
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.state}
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                        <Input
                          maxLength={50}
                          name="state"
                          value={values.state}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="11"
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pin}
                          className={errors.pin && touched.pin ? "empty" : ""}
                        />
                        <Input
                          name="pin"
                          value={values.pin}
                          onBlur={handleBlur}
                          tabIndex="12"
                          handleChange={handleChange}
                          className={errors.pin && touched.pin ? "empty" : ""}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.add}
                          className={
                            errors.regAddress && touched.regAddress
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          row={4}
                          name="regAddress"
                          value={values.regAddress}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="13"
                          className={
                            errors.regAddress && touched.regAddress
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col>
                        <Label title={PageConst.symbol} />
                        <FileUpload
                          nm={PageConst.up + PageConst.symbol}
                          accept=".jpg, .jpeg, .png" // accept="image/*"
                          image
                          file={docByte}
                          name={docnm}
                          sendByte={this.seByte}
                          tabIndex="14"
                        />
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.company.loading,
  companyData: state.company.companyData,
});
const mapDispatchToProps = (dispatch) => ({
  addCompany: (payload) => dispatch(addCompany(payload)),
  getCompanyById: (payload) => dispatch(getCompanyById(payload)),
  updateCompany: (payload) => dispatch(updateCompany(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCompany)
);
