const PageConst = {
  ui: "UPSI Information",
  ud: "Time Stamp*",
  usd: "UPSI date*",
  dpName: "DPName*",
  sn: "Sender Name",
  type: "Type of UPSI*",
  receiver: "Receiver ",
  purpose: "UPSI Purpose*",
  mode: "UPSI Mode*",
  desc: "Description",
  doc: "Document",
  up: "Upload ",
  from: "From",
  to: "To",
  connected: "Connected person",
  twc: "Trading Window Closure",
  dateValid: "UPSI date should be greater than Time Stamp",
  dp: "(Designated Person)",
  cp: "(Connected Person)",
  send:"Send",
  sender:"Sender",
  receive: "Receive",
  info : "Information",
};
export { PageConst };
