import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  masterData: {},
  masterList: [],
  masterType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_MASTER_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        masterList: action.payload.data,
      };
    case actions.GET_MASTER_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_MASTER_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_MASTER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        masterData: action.payload.data,
      };
    case actions.GET_MASTER_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };

    case actions.ADD_MASTER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_MASTER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_MASTER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_MASTER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_MASTER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_MASTER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    case actions.GET_MASTER_TYPE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_MASTER_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        masterType: action.payload.data,
      };
    case actions.GET_MASTER_TYPE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    default:
      return state;
  }
};
