const PageConst = {
  hold:"Add Holding",
  ehold:"Edit Holding",
  scn: "Security name*",
  tos: "Type of security*",
  sh: "No. of security held*",
  tl: "Percentage Held of total Holding*",
};

export { PageConst };
