const PageConst = {
  sr: "Show UPSI Records",
  sb: "Shared By",
  sw: "Shared With",
  fd: "From Date",
  td: "To Date",
  dateValid: "To date should be greater than from date",
  ct: " change to ",
};
export { PageConst };
