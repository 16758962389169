import { axiosAuthGet } from "modules/Axios";
import { dashboardConst } from "modules/config";
import * as actions from "./constant";

export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHBOARD_LIST_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHBOARD_LIST);
    if (response?.success) await dispatch({ type: actions.GET_DASHBOARD_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHBOARD_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHBOARD_LIST_ERROR, error: "Network Error" });
  }
};
export const getGraph = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHBOARD_GRAPH_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHBOARD_GRAPH);
    if (response?.success) await dispatch({ type: actions.GET_DASHBOARD_GRAPH_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHBOARD_GRAPH_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHBOARD_GRAPH_ERROR, error: "Network Error" });
  }
};
export const getCal = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHBOARD_CAL_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHBOARD_CAL);
    if (response?.success) await dispatch({ type: actions.GET_DASHBOARD_CAL_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHBOARD_CAL_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHBOARD_CAL_ERROR, error: "Network Error" });
  }
};
export const getgiGraph = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHGRAPH_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHGRAPH);
    if (response?.success) await dispatch({ type: actions.GET_DASHGRAPH_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHGRAPH_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHGRAPH_ERROR, error: "Network Error" });
  }
};
export const getpreGraph = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHPGRAPH_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHPGRAPH);
    if (response?.success) await dispatch({ type: actions.GET_DASHPGRAPH_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHPGRAPH_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHPGRAPH_ERROR, error: "Network Error" });
  }
};

export const getMUList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHGRAPH_MU_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHGRAPH_MU + payload);
    if (response?.success)
      await dispatch({ type: actions.GET_DASHGRAPH_MU_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHGRAPH_MU_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHGRAPH_MU_ERROR, error: "Network Error" });
  }
};
export const getMPList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHGRAPH_MP_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHGRAPH_MP + payload);
    if (response?.success)
      await dispatch({ type: actions.GET_DASHGRAPH_MP_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHGRAPH_MP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHGRAPH_MP_ERROR, error: "Network Error" });
  }
};

export const getYUList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHGRAPH_YU_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHGRAPH_YU);
    if (response?.success)
      await dispatch({ type: actions.GET_DASHGRAPH_YU_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHGRAPH_YU_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHGRAPH_YU_ERROR, error: "Network Error" });
  }
};

export const getYPList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DASHGRAPH_YP_INITIATED });
    let response = await axiosAuthGet(dashboardConst.GET_DASHGRAPH_YP);
    if (response?.success)
      await dispatch({ type: actions.GET_DASHGRAPH_YP_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DASHGRAPH_YP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DASHGRAPH_YP_ERROR, error: "Network Error" });
  }
};