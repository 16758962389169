import styled from "styled-components";

const StyleCont = styled.div`
  color: #505050;
  background: #f1f1f1;
  border-radius: 1em;
  .rdw-editor-toolbar {
    border-radius: 0.5em 0.5em 0 0;
    margin-bottom: 0;
    border-buttom: none;
  }
  .rdw-editor-main {
    height: 10em;
    padding: 5px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .empty {
    .rdw-editor-main {
      border-radius: 0 0 1em 1em;
      border: 1px solid #e81c1c;
    }
    .rdw-editor-toolbar {
      border: 1px solid #e81c1c;
      border-bottom: none;
  }
  .rdw-block-wrapper span,
  .rdw-dropdown-wrapper a {
    color: #000;
  }
  .public-DraftStyleDefault-block {
    margin: 3px;
  }
  .rdw-editor-main .public-DraftStyleDefault-block {
    margin: 3px 3px 0!important;
  }
`;
export { StyleCont };
