import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { MContainer } from "./style";
import { PageConst } from "components/Table/constant";
import { ButtonConst, FormValid } from "App/AppConstant";
import { getTemplateInfoList } from "redux/templates/actions";
import { Row, Col, Label, Input, Button } from "components/Form";

class ModalUI extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", reserr: false, erValue: "", closerMail: [] };
  }
  async componentDidMount() {
    const { Report } = this.props;
    Report && (await this.props.getTemplateInfoList());
  }
  async componentDidUpdate(prevProps) {
    try {
      const { templateList } = this.props;
      if (templateList !== prevProps.templateList)
        this.setState({ closerMail: templateList });
    } catch (error) {
      console.log(error);
    }
  }
  lblUI = (txt, txt2, a) => {
    try {
      if (txt?.charAt(txt.length - 1) === "*")
        txt = txt.substring(0, txt.length - 1);
      if (txt2)
        return (
          <Col xs={24} sm={24} md={24} lg={12} xl={a ? a : 12}>
            <Label title={txt + " : "} />
            <h3>{txt2}</h3>
          </Col>
        );
    } catch (error) {
      console.log(error);
    }
  };
  submit = () => {
    try {
      const { email } = this.state;
      if (email.trim() === "") this.setState({ reserr: true });
      else {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
          this.props.sendTest(email);
        else this.setState({ reserr: true, erValue: FormValid.emailInvalid });
      }
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date) => {
    try {
      let day = new Date(date).getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date).getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date).getFullYear();
      return date ? day + "-" + month + "-" + year : "";
    } catch (error) {
      console.log(error);
    }
  };
  emailUi = () => {
    try {
      const { data } = this.props;
      const { closerMail } = this.state;
      if (data?.otherText) {
        // {"type":"Closure of Trading Window for All","send":["Kshitij@gmail.com"],"from":"08-11-2022","to":"08-11-2022"}
        let eData = JSON.parse(data?.otherText);
        if (eData.type !== "Test mail") {
          let match = closerMail.find((a) => a.name === eData.type);
          if (match) {
            let temp = match.template;
            if (eData.type === "Closure of Trading Window for All") {
              temp = temp?.replace("DD/MM/YEAR", eData.from);
              temp = temp?.replace("DD/MM/YEAR", eData.to);
            }
            eData.temp = temp;
          }
        }
        return (
          <Row gutter={40}>
            {this.lblUI(PageConst.sdt, eData.send?.toString(), 24)}
            {this.lblUI(PageConst.type, eData.type, 24)}
            {eData.temp && (
              <Col xs={24} sm={24} md={24} lg={12} xl={24}>
                <Label title={PageConst.mtx + " : "} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: eData.temp,
                  }}
                />
              </Col>
            )}
          </Row>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleChange = (e) =>
    this.setState({ email: e.target.value, reserr: false, erValue: "" });
  render() {
    const { toggleModal, data, mail, Report } = this.props;
    const { email, reserr, erValue } = this.state;
    return (
      <MContainer id="mDiv">
        <Modal
          open={true}
          onOk={toggleModal}
          onCancel={toggleModal}
          footer={null}
          title={
            mail
              ? "Send Mail"
              : Report
              ? "Email Details"
              : "Trading Window Closure"
          }
          className="add-modal"
          getContainer={() => document.getElementById("mDiv")}
          centered
        >
          <div className="modalDiv">
            {mail ? (
              <div>
                <Label
                  title={PageConst.email}
                  className={reserr ? "empty" : ""}
                />
                <Input
                  name="email"
                  value={email}
                  className={reserr ? "empty" : ""}
                  handleChange={this.handleChange}
                />
                {reserr && <div className="form-error">{erValue}</div>}
                <Button className="mt-1" onClick={() => this.submit()}>
                  {ButtonConst.sendMail}
                </Button>
              </div>
            ) : Report ? (
              this.emailUi()
            ) : (
              data?.map((a, i) => (
                <Row gutter={40} key={i}>
                  {this.lblUI(PageConst.connected, a.Selection, 24)}
                  {this.lblUI(PageConst.cb, a.unmae, 24)}
                  {this.lblUI(PageConst.from, this.dateChange(a.from))}
                  {this.lblUI(PageConst.to, this.dateChange(a.to))}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Label title={PageConst.purpose + " : "} />
                    <p className={data.length - 1 === i ? "" : "dashb"}>
                      {a.closureReason}
                    </p>
                  </Col>
                </Row>
              ))
            )}
          </div>
        </Modal>
      </MContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  templateList: state.template.templateList,
});
const mapDispatchToProps = (dispatch) => ({
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalUI)
);
