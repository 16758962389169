import styled from "styled-components";

const StyledComponent = styled.div`
  width: 100%;
  margin: 2px 0 0.5em; 
  .ant-picker {
    width: inherit;
    padding: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 45%);    
    border: 1px solid #e2e8f0;
    background-color: #fafafa;
    border-radius: 5px;
    height: 2.5em;
    input {
      height: 2.1em;
      color: #000;
    }
  }
  .ant-picker-suffix,
  .ant-picker-clear {
    color: #000;
  }
  .empty {    
    border: 1px solid #e81c1c;
    box-shadow: 0 0 10px red !important;
  }
  .ant-picker-content {
    height: 210px;
  }
  .ant-picker-disabled {
    background-color: #f5f5f5;}
`;

export { StyledComponent };
