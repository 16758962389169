import { message } from "antd";
import { push } from "connected-react-router";

import { axiosAuthGet, axiosAuthPost, fetchAuthPost } from "modules/Axios";
import { disclosureConst,AuditConst } from "modules/config";
import * as actions from "./constant";

export const getDisclosureList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DISCLOSURE_LIST_INITIATED });
    let response = await axiosAuthGet(disclosureConst.GET_DISCLOSURE_LIST);
    if (response?.success) await dispatch({ type: actions.GET_DISCLOSURE_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DISCLOSURE_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DISCLOSURE_LIST_ERROR, error: "Network Error" });
  }
};

export const getDisclosureById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DISCLOSURE_BY_ID_INITIATED });
    let response = await axiosAuthGet(disclosureConst.GET_DISCLOSURE_BY_ID+id);
    if (response?.success) await dispatch({ type: actions.GET_DISCLOSURE_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DISCLOSURE_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DISCLOSURE_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addDisclosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_DISCLOSURE_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    payload.data && delete payload.data;
    let response = await axiosAuthPost(disclosureConst.ADD_DISCLOSURE, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"Disclosure added"});
      await dispatch({ type: actions.ADD_DISCLOSURE_SUCCESS, payload: response });
      dispatch(push("/disclosures"));
    }
    else dispatch({ type: actions.ADD_DISCLOSURE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_DISCLOSURE_ERROR, error: "Network Error" });
  }
};

export const updateDisclosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_DISCLOSURE_INITIATED });
    let response = await axiosAuthPost(disclosureConst.UPDATE_DISCLOSURE+payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"Disclosure update"});
      await dispatch({ type: actions.UPDATE_DISCLOSURE_SUCCESS, payload: response });}
    else dispatch({ type: actions.UPDATE_DISCLOSURE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_DISCLOSURE_ERROR, error: "Network Error" });
  }
};

export const deleteDisclosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_DISCLOSURE_INITIATED });
    let response = await axiosAuthGet(disclosureConst.DELETE_DISCLOSURE + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"Disclosure record deleted"});
      await dispatch({ type: actions.DELETE_DISCLOSURE_SUCCESS, payload: response });
      message.success(response?.message)
    }
    else dispatch({ type: actions.DELETE_DISCLOSURE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_DISCLOSURE_ERROR, error: "Network Error" });
  }
};
