import styled from "styled-components";

const ReportStyle = styled.div`
  .display .reportSelect {
    width: 15em;
    margin-bottom: 1em;
  }
`;

export { ReportStyle };
