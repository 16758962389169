import { message } from "antd";
import { push } from "connected-react-router";

import { axiosAuthGet, axiosAuthPost, fetchAuthPost } from "modules/Axios";
import { companyConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getCompanyList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_COMPANY_LIST_INITIATED });
    let response = await axiosAuthGet(companyConst.GET_COMPANY_LIST);
    if (response?.success)
      await dispatch({type: actions.GET_COMPANY_LIST_SUCCESS,payload: response});
    else dispatch({type: actions.GET_COMPANY_LIST_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_COMPANY_LIST_ERROR, error: "Network Error" });
  }
};

export const getCompanyById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_COMPANY_BY_ID_INITIATED });
    let response = await axiosAuthGet(companyConst.GET_COMPANY_BY_ID + id);
    if (response?.success)
      await dispatch({type: actions.GET_COMPANY_BY_ID_SUCCESS,payload: response});
    else dispatch({type: actions.GET_COMPANY_BY_ID_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_COMPANY_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addCompany = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_COMPANY_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    let response = await axiosAuthPost(companyConst.ADD_COMPANY, payload.val);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.val.uid, text: "Company added" });
      await dispatch({ type: actions.ADD_COMPANY_SUCCESS, payload: response });
      dispatch(push("/company"));
    }
    else dispatch({ type: actions.ADD_COMPANY_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_COMPANY_ERROR, error: "Network Error" });
  }
};

export const updateCompany = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_COMPANY_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    let response = await axiosAuthPost(companyConst.UPDATE_COMPANY + payload.val.id, payload.val);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.val.uid, toid: payload.val.id, text: "Company updated" });
      await dispatch({ type: actions.UPDATE_COMPANY_SUCCESS, payload: response });
      dispatch(push("/company"));
    } else dispatch({ type: actions.UPDATE_COMPANY_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_COMPANY_ERROR, error: "Network Error" });
  }
};

export const deleteCompany = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_COMPANY_INITIATED });
    let response = await axiosAuthGet(companyConst.DELETE_COMPANY + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "Company delete" });
      await dispatch({type: actions.DELETE_COMPANY_SUCCESS,payload: response});
      message.success(response?.message);
    } else dispatch({type: actions.DELETE_COMPANY_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_COMPANY_ERROR, error: "Network Error" });
  }
};
