export const GET_DIRECTOR_LIST_INITIATED = "GET_DIRECTOR_LIST_INITIATED";
export const GET_DIRECTOR_LIST_SUCCESS = "GET_DIRECTOR_LIST_SUCCESS";
export const GET_DIRECTOR_LIST_ERROR = "GET_DIRECTOR_LIST_ERROR";

export const GET_DIRECTOR_BY_ID_INITIATED = "GET_DIRECTOR_BY_ID_INITIATED";
export const GET_DIRECTOR_BY_ID_SUCCESS = "GET_DIRECTOR_BY_ID_SUCCESS";
export const GET_DIRECTOR_BY_ID_ERROR = "GET_DIRECTOR_BY_ID_ERROR";

export const ADD_DIRECTOR_INITIATED = "ADD_DIRECTOR_INITIATED";
export const ADD_DIRECTOR_SUCCESS = "ADD_DIRECTOR_SUCCESS";
export const ADD_DIRECTOR_ERROR = "ADD_DIRECTOR_ERROR";

export const UPDATE_DIRECTOR_INITIATED = "UPDATE_DIRECTOR_INITIATED";
export const UPDATE_DIRECTOR_SUCCESS = "UPDATE_DIRECTOR_SUCCESS";
export const UPDATE_DIRECTOR_ERROR = "UPDATE_DIRECTOR_ERROR";

export const DELETE_DIRECTOR_INITIATED = "DELETE_DIRECTOR_INITIATED";
export const DELETE_DIRECTOR_SUCCESS = "DELETE_DIRECTOR_SUCCESS";
export const DELETE_DIRECTOR_ERROR = "DELETE_DIRECTOR_ERROR";