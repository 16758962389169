const PageConst = {
  search: "Search",
  sr: "#",
  id: "Id",
  name: "Name",
  mobile: "Mobile No",
  email: "Email",
  un: "User Name",
  cb: "Created By",
  userType: "User Type",
  category: "Category",
  bank: "Bank Name",
  account: "Account No",
  ifsc: "IFSC Code",
  branch: "Branch Name",
  companyName: "Company Name",
  dname: "Document Name",
  fn: "File Name",
  cin: "CIN",
  city: "City",
  gst: "GST",
  insi: "INSI",
  pan: "PAN no.",
  pincode: "Pincode",
  regAddress: "Address",
  state: "State",
  user: "User",
  reqType: "Request Type",
  date: "Date",
  udate: "Time Stamp",
  usdate: "UPSI Date",
  connected: "Connected Person",
  purpose: "Purpose",
  startDate: "Start Date",
  endDate: "End Date",
  trdDate: "Trade Date",
  cr: "Closure Reason",
  sl: "Selection",
  sname: "Shareholder Name",
  dt: "Depository Type",
  sn: "ShareName",
  st: "Security Type",
  rcd: "Record",
  dp: "DP ID",
  phone: "Phone",
  folio: "Folio",
  ssa: "Securities Sold / Acquired",
  designation: "Designation",
  joiningDate: "Joining Date",
  exitDate: "Exit Date",
  receiver: "Receiver",
  sender: "Sender",
  mode: "Mode",
  ts: "Total Share",
  status: "Status",
  action: "Action",
  scn: "Security Name",
  tos: "Type of Security",
  sh: "No. of Security Held",
  tl: "Percentage Held of Total Holding",
  hold: "Holding Details",
  clt: "Client ID",
  tsp: "Total Shares Pledged",
  tsl: "Total Shares Under Lien",
  a1: "Address1",
  a2: "Address2",
  a3: "Address3",
  a4: "Address4",
  cd: "Created Date",
  qua: "Qualification",
  uni: "University",
  ed: "Enrollment Date",
  py: "Passing Year",
  grade: "Grade",
  pdm: "Proposed Quantity",
  ptp: "Proposed Transaction Price",
  tra: "Transaction",
  from: "From",
  to: "To",
  rwd: "Relationship With DP",
  dpName: "Designated Person",
  refName: "Reference",
  rel: "Relation",
  apr: "Approved",
  hld: "Holding",
  bd: "Bank Details",
  edu: "Education",
  emp: "Employment",
  dir: "Directorship",
  port: "Port",
  isin: "ISIN",
  unitCode: "Unit Code",
  secCode: "Sec Code",
  dpid: "DPID",
  holderFol: "HolderFol",
  holder: "Holder",
  second: "Second",
  third: "Third",
  fhName: "FH Name",
  haddress: "Address",
  haddress1: "Address1",
  haddress2: "Address2",
  haddress3: "Address3",
  haddress4: "Holder Pin",
  totalShare: "Total Shares",
  holdMinor: "Hold Minor",
  type: "Type",
  utype: "Type of UPSI",
  fax: "Fax",
  reason: "Reason",
  no: " No.",
  clID: "Client Id",
  prevHol: "Previous Holding",
  previ: "Previous ",
  buying: "Buying",
  plus: "(+)",
  selling: "Selling",
  sub: "(-)",
  presHol: "Present Holding",
  present: "Present ",
  preOfEqu: "% of equity",
  prevq: "Previous Quantity",
  quat: "Quantity",
  period: "Period",
  nap: "Pre-Clearence Approval",
  holAsOn: "Holding As On",
  shares: "Shares",
  log: "Log",
  sdt: "Send to",
  mtx: "Email Text",
  att: "Approved Transaction Type",
  tt: "Transaction Type",
  quantity: "Approved Quantity",
  quality: "Quality",
  buy: "Buy",
  sale: "Sale",
  vq: "Variation Quantity",
  prec: "PreClearance",
  rsd: "Request Submit Date",
  form: " Form",
  rec: "Receiver",
  note: " Note",
  prc: "PreClearance Taken",
  nod: "Name of Declaration",
  desc: "Description",
};

export { PageConst };
