import { message } from "antd";
import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { connReferenceConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getConRefList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CONNREFERENCE_LIST_INITIATED });
    let response = await axiosAuthGet(connReferenceConst.GET_CONNREFERENCE_LIST);
    if (response?.success) await dispatch({ type: actions.GET_CONNREFERENCE_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_CONNREFERENCE_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CONNREFERENCE_LIST_ERROR, error: "Network Error" });
  }
};

export const getConRefById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CONNREFERENCE_BY_ID_INITIATED });
    let response = await axiosAuthGet(connReferenceConst.GET_CONNREFERENCE_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_CONNREFERENCE_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_CONNREFERENCE_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CONNREFERENCE_BY_ID_ERROR, error: "Network Error" });
  }
};

export const getConRefByUId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CONNREFERENCE_BY_UID_INITIATED });
    let response = await axiosAuthGet(connReferenceConst.GET_CONNREFERENCE_BY_UID + id);
    if (response?.success) await dispatch({ type: actions.GET_CONNREFERENCE_BY_UID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_CONNREFERENCE_BY_UID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CONNREFERENCE_BY_UID_ERROR, error: "Network Error" });
  }
};

export const getConRefBydpId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CONNREFERENCE_BY_DPID_INITIATED });
    let response = await axiosAuthGet(connReferenceConst.GET_CONNREFERENCE_BY_DPID + id);
    if (response?.success) await dispatch({ type: actions.GET_CONNREFERENCE_BY_DPID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_CONNREFERENCE_BY_DPID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CONNREFERENCE_BY_DPID_ERROR, error: "Network Error" });
  }
};

export const addConRef = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_CONNREFERENCE_INITIATED });
    let response = await axiosAuthPost(connReferenceConst.ADD_CONNREFERENCE, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, text: "Connection Reference added" });
      await dispatch({ type: actions.ADD_CONNREFERENCE_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_CONNREFERENCE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_CONNREFERENCE_ERROR, error: "Network Error" });
  }
};

export const updateConRef = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_CONNREFERENCE_INITIATED });
    let response = await axiosAuthPost(connReferenceConst.UPDATE_CONNREFERENCE + payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, toid: payload.id, text: "Connection Reference updated" });
      await dispatch({ type: actions.UPDATE_CONNREFERENCE_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.UPDATE_CONNREFERENCE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_CONNREFERENCE_ERROR, error: "Network Error" });
  }
};

export const deleteConRef = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_CONNREFERENCE_INITIATED });
    let response = await axiosAuthGet(connReferenceConst.DELETE_CONNREFERENCE + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "Connection Reference deleted" });
      await dispatch({ type: actions.DELETE_CONNREFERENCE_SUCCESS, payload: response });
      message.success(response?.message)
    }
    else dispatch({ type: actions.DELETE_CONNREFERENCE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_CONNREFERENCE_ERROR, error: "Network Error" });
  }
};
