import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  userData: {},
  userList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USERS_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload.data,
      };
    case actions.GET_USERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_USER_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data,
      };
    case actions.GET_USER_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_USER_BY_DP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_USER_BY_DP_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload.data,
      };
    case actions.GET_USER_BY_DP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.ADD_USER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_USER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.UPDATE_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_PWD_USER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.UPDATE_PWD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.UPDATE_PWD_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.DELETE_USER_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_USER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
