const PageConst = {
  dl: "SMTP Settings",
  email: "Email ID*",
  nd: "From Name*",
  port: "Port*",
  host: "Host*",
  us: "User Name*",
  pwd: "Password*",
  active: "Active",
};
export { PageConst };
