import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { sendmail } from "redux/smtp/actions";
import { ButtonConst, Holidays } from "App/AppConstant";
import { getUserList } from "redux/user/actions";
import { getMasterList } from "redux/master/actions";
import { getConRefList } from "redux/connRef/actions";
import { getTradingList } from "redux/trading/actions";
import { addDisclosure } from "redux/disclosure/actions";
import { getTemplateInfoList } from "redux/templates/actions";
// import { getHoldingByuId } from "redux/holding/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
  DatePicker,
  Checkbox,
  FileUpload,
} from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";
var userId = getAuthUserID();
var role = getAuthRole();
const ValidationSchema = Yup.object().shape({
  // folio: Yup.string().trim().nullable().required(" "),
  startDate: Yup.date().nullable().required(" "),
  securities: Yup.number().nullable().required(" ").test("Is positive?", " ", (value) => value > 0), 
  totalHeld: Yup.number().nullable(),//.test("Is positive?", " ", (value) => value > 0),
  tradeDate: Yup.date().nullable().required(" "),
  endDate: Yup.date()
    .nullable()
    .required(" ")
    .when(
      "startDate",
      (startDate, schema) => startDate && schema.min(startDate, PageConst.mDate)
    ),
  securityType: Yup.string().trim().nullable().required(" "),
  name: Yup.string().trim().nullable().required(" "),
});

class Disclosures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      disable: false,
      above: false,
      clist: [],
      clist2: [],
      ulist: [],
      chList: [],
      preList: [],
      typeList: [], // holding: 0,
      discloserMail: [],
      docByte: "",
      docnm: "",
      init: {
        pre: "",
        preClearance: "",
        aboveTenLacs: "",
        pID: 0, // folio: "",
        folioNo: null,
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        tradeDate: new Date().toISOString().split("T")[0],
        name: "",
        securityType: "",
        securities: null,
        totalHeld: null,
      },
    };
  }
  async componentDidMount() {
    try {
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getConRefList();
      await this.props.getUserList();
      await this.props.getTradingList();
      await this.props.getMasterList();
      await this.props.getTemplateInfoList();
      this.checkHDate();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { tradingList, userList, masterList, connRefList, templateList } =
        this.props;
      if (prevProps.tradingList !== tradingList) {
        const { clist } = this.state;
        let ml = tradingList?.filter(
          (a) => a.is_deleted === 0 && a.type === "Approved"
        ); // if (role === 67) {   }
        let ml1 = ml?.filter((a) => a.connectedID === userId);
        clist?.forEach((l) => {
          let s = ml?.filter((a) => a.connectedID === l.uId);
          s?.forEach((k) => !ml1?.includes(k) && ml1.push(k)); //{if (!ml1?.find(w => w.id === k.id))
        }); // let ml3 = ml1;//.concat(ml2);
        ml1?.forEach((a) => (a.value = "PreClearance-" + a.id));
        this.setState({ preList: ml1 });
      }
      // if (holdinguData !== prevProps.holdinguData) {holdinguData
      //   let holding = holdinguData?.filter((a) => a.is_deleted === 0);
      //   this.setState({ holding: holding[0] ? holding[0].securityNo : 0 });}
      if (connRefList !== prevProps.connRefList) {
        let clist2 = connRefList.filter((c) => c.is_deleted === 0); //c.dpId === userId &&
        let clist = clist2.filter((c) => c.dpId === userId);
        this.setState({ clist, clist2 });
      }
      if (userList !== prevProps.userList) {
        const { clist2 } = this.state;
        let urlist = userList?.filter(
          (a) => a.is_deleted === 0 && a.uid !== 68
        );
        let data = [],
          uslist = [],
          rids = [];
        // if (role === 67) {let uhlist = userList?.filter((a) => a.id === userId);
        //   clist2.forEach((l) => {let s = userList?.find((a) => a.id === l.uId);if (s) uslist.push(s);});
        //   urlist = uhlist.concat(uslist);}
        urlist.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
          rids.push(a.id);
        });
        let uhlist = data?.filter((a) => a.id === userId);
        let uhlist2 = urlist?.filter((a) => a.id === userId);
        let usr = userList?.find(
          (s) => s.id !== userId && uhlist2[0]?.pan === s.pan
        );
        let conList = clist2?.filter(
          (f) => f.dpId === userId || f.dpId === usr?.id
        );
        conList?.forEach((l) => {
          let s = data?.find((a) => a.id === l.uId);
          if (s) uslist.push(s);
        });
        urlist = uhlist.concat(uslist);
        this.setState({ ulist: data, rids, chList: urlist });
      }
      if (prevProps.masterList !== masterList) {
        let ml = masterList.filter(
          (a) => a.typeName?.toLowerCase() === "security type"
        );
        ml?.forEach((a) => (a.value = a.mname));
        this.setState({ typeList: ml });
      }
      if (templateList !== prevProps.templateList)
        this.setState({ discloserMail: templateList });
    } catch (error) {
      console.log(error);
    }
  }
  checkHDate = () => {
    try {
      const { init } = this.state;
      if (this.disabledHDate(init.startDate)) init.startDate = "";
      if (this.disabledHDate(init.endDate)) init.endDate = "";
      if (this.disabledHDate(init.tradeDate)) init.tradeDate = "";
      this.setState({ init });
    } catch (error) {
      console.log(error);
    }
  };
  disabledHDate = (current) => {
    try {
      let match = Holidays?.find(
        (a) => new Date(a)?.toDateString() === new Date(current)?.toDateString()
      );
      return (
        match ||
        new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6
      );
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, setFieldValue, error, disable) => {
    try {
      const { preList, ulist, typeList, chList } = this.state;
      let data =
        name === "pre"
          ? preList
          : name === "name"
          ? disable
            ? ulist
            : chList
          : [];
      if (name === "securityType") data = typeList;
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          defaultValue={val}
          disabled={disable}
          className={error && val === "" ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue(name, value);
            if (name === "pre") {
              setFieldValue("pID", data.id);
              this.setValue(data.id, setFieldValue);
            } else if (name === "name") {
              setFieldValue("cid", data.id);
              // setFieldValue("folioNo", data.id);
              // this.props.getHoldingByuId(data.id);
            }
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date, format) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (format) return year + "-" + month + "-" + day;
    return date ? day + "-" + month + "-" + year : "";
  };
  setValue = (id, setFieldValue) => {
    try {
      const { tradingList } = this.props;
      let a = tradingList?.find((a) => a.id === id);
      if (a) {
        setFieldValue("startDate", this.dateChange(a.startDate, true));
        setFieldValue("endDate", this.dateChange(a.endDate, true));
        setFieldValue("securities", a.securityNo);
        setFieldValue("name", "");
        setTimeout(() => {
          setFieldValue("name", a.connected);
          setFieldValue("folioNo", a.pID);
          setFieldValue("cid", a.connectedID);
        }, 200); // this.props.getHoldingByuId(a.connectedID);
      }
    } catch (error) {
      console.log(error);
    }
  };
  onChange = (setFieldValue, pre) => {
    try {
      const { checked } = this.state;
      if (pre !== "") {
        setFieldValue("pre", "");
        setFieldValue("preClearance", "");
        setFieldValue("aboveTenLacs", "");
        setFieldValue("pID", 0);
        setFieldValue("startDate", "");
        setFieldValue("endDate", "");
        setFieldValue("securities", "");
        setFieldValue("securityType", "");
        setFieldValue("name", "");
        setFieldValue("folioNo", null);
        setFieldValue("totalHeld", "");
        this.setState({ checked: !checked, holding: 0 });
      } else this.setState({ checked: !checked });
    } catch (error) {
      console.log(error);
    }
  };
  onaChange = () => this.setState({ above: !this.state.above });
  sendMail = async (toData, uid) => {
    try {
      const { discloserMail, above } = this.state; // let toData = this.findToUser(a);
      let i = above ? (uid === 69 ? 4 : 3) : 2;
      if (toData?.length > 0 && discloserMail[i]) {
        let temp = discloserMail[i]?.template;
        let mailData = {
          to: toData,
          subject: discloserMail[i]?.name,
          html: temp,
        };
        let postData = {
          uid: userId,
          mail: mailData,
          text: { type: discloserMail[i]?.name, send: toData },
        };
        await this.props.sendmail(postData);
      } else message.error(PageConst.ce);
    } catch (error) {
      console.log(error);
    }
  };
  sendToUser = (a) => {
    try {
      const { userList } = this.props;
      let user = userList?.find((b) => b.is_deleted === 0 && b.id === a.cid);
      if (user) this.sendMail(user.email, user.uid);
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { checked, above, docByte, docnm } = this.state;
      if (docnm === "") message.error(PageConst.pfu);
      else {
        values.preClearance = checked ? 1 : 0;
        values.aboveTenLacs = above && checked ? 1 : 0;
        values.note = docnm;
        values.data = docByte;
        values.uid = userId; // values.totalHeld = holding;
        delete values["pre"];
        await this.props.addDisclosure(values);
        this.sendToUser(values);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  seByte = (byteCode, name) =>
    this.setState({ docByte: byteCode, docnm: name });
  render() {
    const { init, disable, checked, above, docByte, docnm } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.title}</h4>
              <Button onClick={() => this.props.history.push("/disclosures")}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.pct} />
                        <div className="flex">
                          <Checkbox
                            checked={checked}
                            text={PageConst.yes}
                            onChange={() =>
                              this.onChange(setFieldValue, values.pre)
                            }
                          />
                          <Checkbox
                            checked={!checked}
                            text={PageConst.no}
                            onChange={() =>
                              this.onChange(setFieldValue, values.pre)
                            }
                          />
                        </div>
                      </Col>
                      {checked ? (
                        <>
                          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                            <Label title={PageConst.above} />
                            <div className="flex">
                              <Checkbox
                                onChange={this.onaChange}
                                checked={above}
                                text={PageConst.yes}
                              />
                              <Checkbox
                                onChange={this.onaChange}
                                checked={!above}
                                text={PageConst.no}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                            <Label
                              title={PageConst.pre}
                              className={
                                errors.pre && touched.pre ? "empty" : ""
                              }
                            />
                            {values.pre === "" &&
                              this.selectUI(
                                values.pre,
                                "pre",
                                setFieldValue,
                                errors.pre && touched.pre
                              )}
                            {values.pre !== "" &&
                              this.selectUI(values.pre, "pre", setFieldValue)}
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.folio} />
                        <Input type="number"name="folio"value={values.folio}onBlur={handleBlur}tabIndex="3"
                          handleChange={(val) =>this.chnageNum(setFieldValue, "folio", val)}/></Col> */}
                      {checked && (
                        <>
                          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                            <Label
                              title={PageConst.dts}
                              className={
                                errors.startDate && touched.startDate
                                  ? "empty"
                                  : ""
                              }
                            />
                            <DatePicker
                              holiday
                              disabled={checked}
                              value={values.startDate}
                              handleChange={handleChange("startDate")}
                              tabIndex="4"
                              className={
                                errors.startDate && touched.startDate
                                  ? "empty"
                                  : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                            <Label
                              title={PageConst.dte}
                              className={
                                errors.endDate && touched.endDate ? "empty" : ""
                              }
                            />
                            <DatePicker
                              holiday
                              disabled={checked}
                              value={values.endDate}
                              handleChange={handleChange("endDate")}
                              tabIndex="5"
                              className={
                                errors.endDate && touched.endDate ? "empty" : ""
                              }
                            />
                            {errors.endDate && touched.endDate ? (
                              <div className="form-error">{errors.endDate}</div>
                            ) : null}
                          </Col>
                        </>
                      )}
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.td}
                          className={
                            errors.tradeDate && touched.tradeDate ? "empty" : ""
                          }
                        />
                        <DatePicker
                          holiday
                          value={values.tradeDate}
                          handleChange={handleChange("tradeDate")}
                          tabIndex="6"
                          className={
                            errors.tradeDate && touched.tradeDate ? "empty" : ""
                          }
                        />
                        {errors.tradeDate && touched.tradeDate ? (
                          <div className="form-error">{errors.tradeDate}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.name}
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        {values.name === "" &&
                          this.selectUI(
                            values.name,
                            "name",
                            setFieldValue,
                            errors.name && touched.name,
                            checked
                          )}
                        {values.name !== "" &&
                          this.selectUI(
                            values.name,
                            "name",
                            setFieldValue,
                            false,
                            checked
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.ts}
                          className={
                            errors.securityType && touched.securityType
                              ? "empty"
                              : ""
                          }
                        />
                        {values.securityType === "" &&
                          this.selectUI(
                            values.securityType,
                            "securityType",
                            setFieldValue,
                            errors.securityType && touched.securityType
                          )}
                        {values.securityType !== "" &&
                          this.selectUI(
                            values.securityType,
                            "securityType",
                            setFieldValue
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.nss}
                          className={
                            errors.securities && touched.securities
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          min="1"
                          name="securities"
                          value={values.securities} // disabled={checked}
                          onBlur={handleBlur}
                          tabIndex="8" 
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "securities", val)
                          }
                          className={
                            errors.securities && touched.securities
                              ? "empty"
                              : ""
                          }
                        />
                      </Col> 
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.total} />
                        <Input //disabled={true}
                          type="number"                          
                          value={values.totalHeld}
                          onBlur={handleBlur}
                          tabIndex="9"
                          name="totalHeld"
                          handleChange={(val) =>this.chnageNum(setFieldValue, "totalHeld", val)} 
                        />
                      </Col>
                      <Col>
                        <Label title={PageConst.cn} />
                        <FileUpload
                          nm={PageConst.un}
                          accept=".pdf" // accept="image/*"
                          image
                          file={docByte}
                          name={docnm}
                          sendByte={this.seByte}
                          tabIndex="8"
                        />
                      </Col>
                    </Row>
                    <div className="btnDiv">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.trading.loading,
  tradingList: state.trading.tradingList,
  userList: state.user.userList,
  masterList: state.master.masterList,
  connRefList: state.connref.connRefList,
  templateList: state.template.templateList,
});
const mapDispatchToProps = (dispatch) => ({
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  addDisclosure: (payload) => dispatch(addDisclosure(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
  sendmail: (payload) => dispatch(sendmail(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Disclosures)
);
