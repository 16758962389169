export const GET_BANK_LIST_INITIATED = "GET_BANK_LIST_INITIATED";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_ERROR = "GET_BANK_LIST_ERROR";

export const GET_BANK_BY_UID_INITIATED = "GET_BANK_BY_UID_INITIATED";
export const GET_BANK_BY_UID_SUCCESS = "GET_BANK_BY_UID_SUCCESS";
export const GET_BANK_BY_UID_ERROR = "GET_BANK_BY_UID_ERROR";

export const ADD_BANK_INITIATED = "ADD_BANK_INITIATED";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_ERROR = "ADD_BANK_ERROR";

export const UPDATE_BANK_INITIATED = "UPDATE_BANK_INITIATED";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_ERROR = "UPDATE_BANK_ERROR";

export const DELETE_BANK_INITIATED = "DELETE_BANK_INITIATED";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_ERROR = "DELETE_BANK_ERROR";