import React, { Component } from "react";
import { Table } from "antd";
import { withRouter } from "react-router-dom";

class TableType extends Component {
  render() {
    const { columns, data } = this.props;
    return (
      <>
        <Table
          className="tableType"
          columns={columns}
          dataSource={data}
          bordered
          size="middle"
          pagination={false}
        />
      </>
    );
  }
}
export default withRouter(TableType);
