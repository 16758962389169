import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBankByUId, deleteBank } from "redux/bank/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getAuthRole } from "modules/helper";
var userRole = getAuthRole();

class Banks extends Component {
  constructor(props) {
    super(props);
    this.state = { ulist: [], id: "", search: "" };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userRole = userRole ? userRole : getAuthRole();
      await this.props.getBankByUId(window.atob(match?.params?.id));
      this.setState({ id: match?.params?.id });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { bankData } = this.props;
      if (bankData !== prevProps.bankData) {
        let ulist = bankData?.filter((a) => a.is_deleted === 0);
        this.setState({ ulist });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (i) => {
    try {
      const { id } = this.state;
      await this.props.deleteBank(i);
      await this.props.getBankByUId(window.atob(id));
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  goBack = () => {
    if (localStorage.url) this.props.history.push(localStorage.url);
    else this.props.history.goBack();
  };
  render() {
    const { ulist, id, search } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.bd}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {(userRole !== 68 || localStorage.url === "/profile") && (
                  <Button onClick={() => this.redirect("/add-bank/" + id)}>
                    {ulist.length > 0 ? ButtonConst.change : ButtonConst.add}
                  </Button>
                )}
                <Button onClick={() => this.goBack()}>
                  {ButtonConst.back}
                </Button>
              </div>
            </div>
            <div className="display">
              <Table
                data={ulist}
                type="bank"
                tab="bank"
                search={search}
                deleteCol={this.removeData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.bank.loading,
  bankData: state.bank.bankData,
});
const mapDispatchToProps = (dispatch) => ({
  getBankByUId: (payload) => dispatch(getBankByUId(payload)),
  deleteBank: (payload) => dispatch(deleteBank(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Banks));
