export const GET_BENPO_LIST_INITIATED = "GET_BENPO_LIST_INITIATED";
export const GET_BENPO_LIST_SUCCESS = "GET_BENPO_LIST_SUCCESS";
export const GET_BENPO_LIST_ERROR = "GET_BENPO_LIST_ERROR";

export const GET_BENPO_LIST_BY_ID_INITIATED = "GET_BENPO_LIST_BY_ID_INITIATED";
export const GET_BENPO_LIST_BY_ID_SUCCESS = "GET_BENPO_LIST_BY_ID_SUCCESS";
export const GET_BENPO_LIST_BY_ID_ERROR = "GET_BENPO_LIST_BY_ID_ERROR";

export const GET_BENPO_LIST_BY_DAY_INITIATED = "GET_BENPO_LIST_BY_DAY_INITIATED";
export const GET_BENPO_LIST_BY_DAY_SUCCESS = "GET_BENPO_LIST_BY_DAY_SUCCESS";
export const GET_BENPO_LIST_BY_DAY_ERROR = "GET_BENPO_LIST_BY_DAY_ERROR";

export const ADD_BENPO_LIST_INITIATED = "ADD_BENPO_LIST_INITIATED";
export const ADD_BENPO_LIST_SUCCESS = "ADD_BENPO_LIST_SUCCESS";
export const ADD_BENPO_LIST_ERROR = "ADD_BENPO_LIST_ERROR";

export const ADD_BENPODIF_LIST_INITIATED = "ADD_BENPODIF_LIST_INITIATED";
export const ADD_BENPODIF_LIST_SUCCESS = "ADD_BENPODIF_LIST_SUCCESS";
export const ADD_BENPODIF_LIST_ERROR = "ADD_BENPODIF_LIST_ERROR";

export const UPDATE_BENPO_LIST_INITIATED = "UPDATE_BENPO_LIST_INITIATED";
export const UPDATE_BENPO_LIST_SUCCESS = "UPDATE_BENPO_LIST_SUCCESS";
export const UPDATE_BENPO_LIST_ERROR = "UPDATE_BENPO_LIST_ERROR";

export const DELETE_BENPO_LIST_INITIATED = "DELETE_BENPO_LIST_INITIATED";
export const DELETE_BENPO_LIST_SUCCESS = "DELETE_BENPO_LIST_SUCCESS";
export const DELETE_BENPO_LIST_ERROR = "DELETE_BENPO_LIST_ERROR";

export const DELETE_BENPO_DATE_INITIATED = "DELETE_BENPO_DATE_INITIATED";
export const DELETE_BENPO_DATE_SUCCESS = "DELETE_BENPO_DATE_SUCCESS";
export const DELETE_BENPO_DATE_ERROR = "DELETE_BENPO_DATE_ERROR";