import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst } from "container/AddUser/constant";
import { ButtonConst, FormValid, ifscConst } from "App/AppConstant";
import {
  addBank,
  updateBank,
  getBankByUId,
  deleteBank,
} from "redux/bank/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  LayOut,
} from "components/Form";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  bankName: Yup.string()
    .trim()
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid)
    .required(" "),
  branch: Yup.string()
    .trim()
    .matches(/^[aA-zZ0-9.,()'-\s]+$/, FormValid.charValid)
    .required(" "),
  bankAccount: Yup.string().nullable().required(" "), // .min(6).max(18).matches(/^[A-Z0-9\s]+$/, FormValid.digitsValid),
  ifsc: Yup.string()
    .trim()
    .nullable()
    .matches(ifscConst, FormValid.ifscValid)
    .required(" "),
});
class BankDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      id: "",
      disable: false,
      show: true,
      deleteEdu: [],
      init: {
        id: 0,
        bankAccount: null,
        bankName: "",
        ifsc: "",
        branch: "",
      },
      bank: [
        {
          id: 0,
          bankAccount: null,
          bankName: "",
          ifsc: "",
          branch: "",
        },
      ],
      counter: 0,
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      await this.props.getBankByUId(window.atob(match?.params?.id));
      this.setState({ id: match?.params?.id });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { bankData } = this.props;
      if (bankData !== prevProps.bankData) {
        let arr = [];
        bankData?.forEach((a) => {
          let data = {
            id: a.id,
            uid: a.uid,
            branch: a.branch,
            bankAccount: a.bankAccount,
            bankName: a.bankName,
            ifsc: a.ifsc,
          };
          if (a.is_deleted === 0) arr.push(data);
        });
        if (arr.length > 0) this.setState({ bank: arr, counter: arr.length });
      }
    } catch (error) {
      console.log(error);
    }
  }
  btnUI = (i, values, handleReset) => {
    try {
      const { bank, counter, disable } = this.state;
      let length = bank.length;
      if (!bank[length - 1]) length = length - 1;
      return (
        <div className="btnDiv">
          {length - 1 === i ? (
            <>
              {values.bankAccount !== null &&
                values.bankName !== "" &&
                values.branch !== "" &&
                values.ifsc.toString() !== "" && (
                  <Button
                    onClick={() => {
                      this.addEdu(counter + 1, i, values);
                    }}
                  >
                    {ButtonConst.an}
                  </Button>
                )}
              <Button
                htmlType="submit"
                disabled={disable}
                onClick={() => {
                  this.save(i + 1, values);
                }}
              >
                {ButtonConst.save}
              </Button>
            </>
          ) : (
            <Button
              htmlType="submit"
              onClick={() => {
                this.save(i + 1, values);
              }}
            >
              {ButtonConst.save}
            </Button>
          )}
          {bank.length > 1 && (
            <Button
              onClick={() => {
                this.remove(i, handleReset);
              }}
            >
              {ButtonConst.remove}
            </Button>
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  addEdu = (key, i, val) => {
    try {
      const { bank, init } = this.state;
      if (key === i + 1) bank[key - 1] = val;
      else bank[i] = val;
      bank[key] = init;
      this.setState({ bank, counter: key });
    } catch (error) {
      console.log(error);
    }
  };
  save = (key, val) => {
    try {
      const { bank } = this.state;
      bank[key - 1] = val;
      this.setState({ bank });
    } catch (error) {
      console.log(error);
    }
  };
  remove = (key, handleReset) => {
    try {
      const { bank, counter, deleteEdu } = this.state;
      bank[key].id !== 0 && deleteEdu.push(bank[key].id);
      bank.splice(key, 1);
      this.setState({ bank, counter: counter - 1, deleteEdu });
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { bank, id, deleteEdu } = this.state;
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      bank.forEach(async (a) => {
        if (a.branch && a.bankAccount && a.bankName && a.ifsc) {
          let data = {
            branch: a.branch,
            bankAccount: a.bankAccount,
            bankName: a.bankName,
            ifsc: a.ifsc?.toUpperCase(),
            uid: window.atob(id),
          };
          if (a.id > 0) {
            data.id = a.id;
            await this.props.updateBank(data);
          } else await this.props.addBank(data);
        }
      });
      deleteEdu?.forEach(async (a) => await this.props.deleteBank(a));
      message.success("Data saved");
      this.back(id);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  back = (id) => this.props.history.push("/bank/" + id);
  render() {
    const { bank, id } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.bd}</h4>
              <div className="bottomDiv">
                <div className="btn">
                  <Button onClick={() => this.back(id)}>
                    {ButtonConst.back}
                  </Button>
                </div>
              </div>
            </div>
            {bank?.map((data, i) => (
              <Collapse
                key={i}
                data={[
                  {
                    header: <span>{PageConst.dtl}</span>,
                    pData: (
                      <Formik
                        initialValues={data}
                        validationSchema={ValidationSchema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset,
                          setFieldValue,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            noValidate
                            key={"dir" + i}
                          >
                            <Row gutter={40}>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.bank}
                                  className={
                                    errors.bankName && touched.bankName
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <Input
                                  name="bankName"
                                  value={values.bankName}
                                  handleChange={handleChange}
                                  onBlur={handleBlur}
                                  tabIndex="16"
                                  className={
                                    errors.bankName && touched.bankName
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {errors.bankName && touched.bankName && (
                                  <div className="form-error">
                                    {errors.bankName}
                                  </div>
                                )}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.account}
                                  className={
                                    errors.bankAccount && touched.bankAccount
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <Input
                                  type="number"
                                  min="1"
                                  name="bankAccount"
                                  value={values.bankAccount}
                                  tabIndex="17"
                                  onBlur={handleBlur}
                                  handleChange={(val) =>
                                    this.chnageNum(
                                      setFieldValue,
                                      "bankAccount",
                                      val
                                    )
                                  }
                                  className={
                                    errors.bankAccount && touched.bankAccount
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {errors.bankAccount && touched.bankAccount && (
                                  <div className="form-error">
                                    {errors.bankAccount}
                                  </div>
                                )}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.ifsc}
                                  className={
                                    errors.ifsc && touched.ifsc ? "empty" : ""
                                  }
                                />
                                <Input
                                  onBlur={handleBlur}
                                  name="ifsc"
                                  value={values.ifsc?.toUpperCase()}
                                  handleChange={handleChange}
                                  tabIndex="18"
                                  className={
                                    errors.ifsc && touched.ifsc ? "empty" : ""
                                  }
                                />
                                {errors.ifsc && touched.ifsc && (
                                  <div className="form-error">
                                    {errors.ifsc}
                                  </div>
                                )}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.branch}
                                  className={
                                    errors.branch && touched.branch
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <Input
                                  onBlur={handleBlur}
                                  name="branch"
                                  value={values.branch}
                                  handleChange={handleChange}
                                  tabIndex="19"
                                  className={
                                    errors.branch && touched.branch
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {errors.branch && touched.branch && (
                                  <div className="form-error">
                                    {errors.branch}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            {this.btnUI(i, values, handleReset)}
                          </Form>
                        )}
                      </Formik>
                    ),
                  },
                ]}
              />
            ))}
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.bank.loading,
  bankData: state.bank.bankData,
});
const mapDispatchToProps = (dispatch) => ({
  addBank: (payload) => dispatch(addBank(payload)),
  updateBank: (payload) => dispatch(updateBank(payload)),
  getBankByUId: (payload) => dispatch(getBankByUId(payload)),
  deleteBank: (payload) => dispatch(deleteBank(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BankDetail)
);
