import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { bankConst, AuditConst } from "modules/config";
import * as actions from "./constant";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId = userId ? userId : getAuthUserID()
export const getBankList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BANK_LIST_INITIATED });
    let response = await axiosAuthGet(bankConst.GET_BANK_LIST);
    if (response?.success)
      await dispatch({ type: actions.GET_BANK_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BANK_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BANK_LIST_ERROR, error: "Network Error" });
  }
};

export const getBankByUId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BANK_BY_UID_INITIATED });
    let response = await axiosAuthGet(bankConst.GET_BANK_BY_UID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_BANK_BY_UID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BANK_BY_UID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BANK_BY_UID_ERROR, error: "Network Error" });
  }
};

export const addBank = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_BANK_INITIATED });
    let response = await axiosAuthPost(bankConst.ADD_BANK, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, text: "Bank added" });
      await dispatch({ type: actions.ADD_BANK_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_BANK_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_BANK_ERROR, error: "Network Error" });
  }
};

export const updateBank = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_BANK_INITIATED });
    let response = await axiosAuthPost(bankConst.UPDATE_BANK + payload.id, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "Bank update" });
      await dispatch({ type: actions.UPDATE_BANK_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.UPDATE_BANK_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_BANK_ERROR, error: "Network Error" });
  }
};

export const deleteBank = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_BANK_INITIATED });
    let response = await axiosAuthGet(bankConst.DELETE_BANK + id);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: id, text: "Bank deleted" });
      await dispatch({ type: actions.DELETE_BANK_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETE_BANK_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_BANK_ERROR, error: "Network Error" });
  }
};
