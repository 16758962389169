import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst, TempConst } from "./constant";
import { ButtonConst } from "App/AppConstant";
import {
  Label,
  Button,
  Row,
  Col,
  LayOut,
  Select,
  RichTextBox,
} from "components/Form";
import { getAuthUserID } from "modules/helper";
import {
  addTemplateInfo,
  getTemplateInfoList,
  updateTemplateInfo,
} from "redux/templates/actions";
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  template: Yup.string().trim().nullable().required(" "),
  name: Yup.string().trim().nullable().required(" "),
});

class AddDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      docByte: "",
      docnm: "",
      tList: [],
      init: { name: "", template: "" },
    };
  }
  async componentDidMount() {
    userId = userId ? userId : getAuthUserID();
    await this.props.getTemplateInfoList();
  }
  componentDidUpdate(prevProps) {
    const { isSaved, templateList } = this.props;
    if (isSaved !== prevProps.isSaved) this.props.getTemplateInfoList();
    if (templateList !== prevProps.templateList)
      this.setState({ tList: templateList });
  }
  selectUI = (val, setFieldValue, error) => {
    try {
      return (
        <Select
          withID
          name={"name"}
          data={TempConst}
          value={val}
          defaultValue={val}
          className={error ? "empty" : ""}
          onChange={async (value) => {
            const { tList } = this.state;
            setFieldValue("name", value);
            let td = tList?.find((a) => a.name === value);
            if (td) setFieldValue("template", td.template);
            else setFieldValue("template", "");
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  reChange = (a) => a?.replace("background-color: rgb(241,241,241);", "");
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { templateList } = this.props;
      values.uid = userId;
      values.template = this.reChange(values.template);
      values.template = this.reChange(values.template);
      values.template = this.reChange(values.template);
      let td = templateList?.find((a) => a.name === values.name);
      if (td) {
        values.id = td.id;
        await this.props.updateTemplateInfo(values);
      } else await this.props.addTemplateInfo(values);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { init, disable } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.dl}</h4>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={15} xl={12}>
                        <Label
                          title={PageConst.uf}
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        {values.name === "" &&
                          this.selectUI(
                            values.name,
                            setFieldValue,
                            errors.name && touched.name
                          )}
                        {values.name !== "" &&
                          this.selectUI(values.name, setFieldValue)}
                      </Col>
                    </Row>
                    <div className="tempDiv">
                      <Label
                        title={PageConst.tmp}
                        className={
                          errors.template && touched.template ? "empty" : ""
                        }
                      />
                      {/* <Input row={8}name="template"value={values.template}onBlur={handleBlur}handleChange={handleChange}className={errors.template && touched.template ? "empty" : ""}/> */}
                      <RichTextBox
                        name={values.name}
                        value={values.template}
                        maxLength={10000}
                        onBlur={handleBlur}
                        changeTxt={(val) => setFieldValue("template", val)}
                        className={
                          (errors.template && touched.template) ||
                          values.template?.trim() === "<p></p>"
                            ? "empty"
                            : ""
                        }
                      />
                    </div>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.template.loading,
  templateList: state.template.templateList,
  isSaved: state.template.isSaved,
});
const mapDispatchToProps = (dispatch) => ({
  addTemplateInfo: (payload) => dispatch(addTemplateInfo(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
  updateTemplateInfo: (payload) => dispatch(updateTemplateInfo(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDeclaration)
);
