import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";

import { StyledComponent } from "./style";
const dateFormat = "YYYY-MM-DD";
const Holidays = ["Jan 26, 2021", "Mar 11, 2021", "Mar 29, 2021", "Apr 02, 2021", "Apr 14, 2021", "Apr 21, 2021", "Apr 25, 2021", "May 01, 2021", "May 13, 2021", "Jul 20, 2021", "Sep 10, 2021", "Oct 02, 2021", "Oct 15, 2021", "Oct 20, 2021", "Nov 04, 2021", "Nov 19, 2021", "Dec 25, 2021", "Jan 26, 2022", "Mar 01, 2022", "Mar 19, 2022", "Apr 10, 2022", "Apr 14, 2022", "Apr 14, 2022", "Apr 15, 2022", "May 01, 2022", "May 03, 2022", "Jul 10, 2022", "Aug 31, 2022", "Oct 02, 2022", "Oct 05, 2022", "Oct 24, 2022", "Oct 24, 2022", "Nov 08, 2022", "Dec 25, 2022", "Jan 26, 2023", "Mar 08, 2023", "Mar 18, 2023", "Mar 30, 2023", "Apr 04, 2023", "Apr 07, 2023", "Apr 14, 2023", "Apr 22, 2023", "May 01, 2023", "Jun 28, 2023", "Aug 15, 2023", "Aug 29, 2023", "Sep 19, 2023", "Oct 02, 2023", "Oct 24, 2023", "Nov 12, 2023", "Nov 14, 2023", "Nov 27, 2023", "Dec 25, 2023", "Jan 26, 2024", "Mar 08, 2024", "Mar 25, 2024", "Mar 29, 2024", "Apr 10, 2024", "Apr 14, 2024", "Apr 17, 2024", "Apr 21, 2024", "May 01, 2024", "Jun 17, 2024", "Jul 17, 2024", "Aug 15, 2024", "Sep 07, 2024", "Oct 02, 2024", "Oct 13, 2024", "Nov 01, 2024", "Nov 02, 2024", "Nov 15, 2024", "Dec 25, 2024"]

class Datepicker extends Component {
  handleChange = (date, dateString) => {
    this.props.handleChange(dateString);
  };
  disabledDate(current) {
    try {
      let prev_date = new Date();
      prev_date.setDate(prev_date.getDate() - 1);
      return current && current.valueOf() < prev_date.getTime();
    } catch (error) {
      console.log(error);
    }
  }
  disabledNextDate(current) {
    try {
      let prev_date = new Date();
      prev_date.setDate(prev_date.getDate());
      return current && current.valueOf() > prev_date.getTime();
    } catch (error) {
      console.log(error);
    }
  }
  disabledHolidayDate = (current) => {
    try {
      let match = Holidays?.find(
        (a) => new Date(a)?.toDateString() === new Date(current)?.toDateString()
      );
      return (
        match ||
        new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6
      );
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      className,
      disabled,
      disableDate,
      defaultValue,
      value,
      id,
      holiday,
    } = this.props;
    let did = id ? "form-dropdown" + id : "form-dropdown";
    return (
      <StyledComponent id={did}>
        <DatePicker
          placeholder=""
          placement={"bottomLeft"}
          className={className}
          disabled={disabled}
          format={dateFormat}
          disabledDate={
            disableDate
              ? this.disabledDate
              : holiday && this.disabledHolidayDate
          }
          value={value && moment(value, dateFormat)}
          defaultValue={defaultValue && moment(defaultValue, dateFormat)}
          onChange={this.handleChange}
          getPopupContainer={() => document.getElementById(did)}
        />
      </StyledComponent>
    );
  }
}
Datepicker.defaultProps = {};
export default Datepicker;
