import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, message } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst, genderList } from "./constant";
import { getMasterList } from "redux/master/actions";
import { ButtonConst, FormValid, panConst } from "App/AppConstant";
import {
  getUserList,
  getUserById,
  addUser,
  updateUser,
} from "redux/user/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
  DatePicker,
  MultiSelect,
} from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";

var userId = getAuthUserID();
var userRole = getAuthRole();
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .nullable()
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid)
    .required(" "),
  surname: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  fatherName: Yup.string()
    .trim()
    .nullable()
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  clientID: Yup.string().trim().nullable().max(10),
  mobile: Yup.string()
    .nullable()
    .min(10)
    .max(10)
    .matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  email: Yup.string().trim().min(3).email(FormValid.emailInvalid).required(" "),
  gender: Yup.string().trim().nullable().required(" "),
  // userType: Yup.string().trim().nullable().required(" "),
  // category: Yup.array().min(1),//.required(" "),
  nationality: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  pan: Yup.string()
    .max(10)
    .min(10)
    .trim()
    .nullable()
    .matches(panConst, FormValid.panValid)
    .required(" "),
  aadhar: Yup.string().trim().nullable().max(12).min(12),
  din: Yup.string()
    .trim()
    .nullable()
    .max(8)
    .min(8)
    .matches(/^[A-Z0-9\s]+$/, FormValid.charValid),
  address: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ0-9.,()'-\s]+$/, FormValid.charValid),
  pincode: Yup.number()
    .nullable()
    .min(100000," ")
    .max(999999," ")
    .required(" ").test("Is positive?", " ", (value) => value > 0), //.matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  city: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  state: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  dpId: Yup.string()
    .trim()
    .max(10),
    // .matches(/^[aA-zZ\s]{2}[0-9]{6}$/, FormValid.dpidValid),
  passport: Yup.string()
    .trim()
    .nullable()
    .matches(/^[aA-zZ\s]{1}[1-9]{1}[0-9]{5}[1-9]{1}$/, FormValid.passportValid),
  userName: Yup.string()
    .trim()
    .required(" ")
    .matches(
      /^[a-zA-Z0-9]+[a-zA-Z0-9~!@#$%^&*()_+"';:,.?-]+$/,
      FormValid.charValid
    ),
});
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      edit: false,
      profile: false,
      serror: false,
      uc: [],
      disData: [],
      usrlist: [],
      id: "",
      path: "",
      title: "",
      initBasic: {
        name: "",
        surname: "",
        fatherName: "",
        email: "",
        mobile: "",
        category: [],
        userType: "",
        pan: "",
        din: null,
        gender: "",
        nationality: "",
        dpId: "",
        clientID: null,
        address: "",
        city: "",
        state: "",
        pincode: null,
        aadhar: null,
        passport: "",
        dateOfAppointment: null,
        userName: "",
        isDP: 1,
        uid: 68,
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      userRole = userRole ? userRole : getAuthRole();
      let path = match.path?.split(":")[0]?.replace(/\//g, "");
      let path2 = path?.split("-")[1]?.toLowerCase();
      let title = path
        ?.replace(/-/g, " ")
        .replace(
          /(^\w|\s\w)(\S*)/g,
          (_, m1, m2) => m1?.toUpperCase() + m2?.toLowerCase()
        );
      title = title.replace("Pcs", "PCS");
      this.setState({ path: path2, title });
      if (match?.path?.toLowerCase().includes("/edit")) {
        await this.props.getUserById(window.atob(match?.params?.id));
        this.setState({ edit: true, id: match?.params?.id });
      } else if (match?.path?.toLowerCase().includes("/profile")) {
        localStorage.url = match.url;
        await this.props.getUserById(userId);
        this.setState({ edit: true, profile: true, id: userId });
      }
      await this.props.getUserList();
      await this.props.getMasterList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userData, masterList, isSaved, userList } = this.props;
      if (isSaved !== prevProps.isSaved) {
        if (this.state.profile) this.props.history.push("/");
        else isSaved && this.props.history.goBack();
      }
      if (masterList !== prevProps.masterList) {
        let ulist = masterList?.filter((a) => a.is_deleted === 0);
        ulist?.forEach((a) => (a.value = a.mname));
        let catList = ulist?.filter((a) => a.typeName === "User Category");
        let data = [],
          i = 2;
        catList?.forEach((a) => {
          if (data.includes(a.mname)) {
            data.push(a.mname + ` (${i})`);
            i = i + 1;
          } else data.push(a.mname);
        });
        this.setState({ uc: data });
      }
      if (userData !== prevProps.userData) {
        let cData = {
          id: userData.id,
          name: userData.name,
          surname: userData.surname,
          fatherName: userData.fatherName,
          email: userData.email,
          mobile: userData.mobile,
          category: userData.category?.split(","),
          pan: userData.pan,
          din: userData.din,
          gender: userData.gender,
          nationality: userData.nationality,
          dpId: userData.dpId,
          clientID: userData.clientID,
          address: userData.address,
          city: userData.city,
          state: userData.state,
          pincode: userData.pincode,
          aadhar: userData.aadhar,
          passport: userData.passport,
          bankAccount: userData.bankAccount,
          bankName: userData.bankName,
          ifsc: userData.ifsc,
          branch: userData.branch,
          dateOfAppointment: userData.dateOfAppointment,
          userName: userData.userName,
        };
        this.setState({
          initBasic: cData,
          disData: userData.category?.split(","),
        });
      }
      if (userList !== prevProps.userList) {
        let data = [];
        let urlist = userList.filter((a) => a.is_deleted === 0);
        urlist?.forEach((a) => data.push({ id: a.id, pan: a.pan, uid: a.uid }));
        this.setState({ usrlist: data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, name, data, setFieldValue, error) => {
    try {
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          showSearch={"No"}
          defaultValue={val}
          className={error ? "empty" : ""}
          onChange={async (value, data) => setFieldValue(name, value)}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  selectChange = (e) => this.setState({ disData: e, serror: false });
  selectmUI = (val, setFieldValue, error) => {
    try {
      const { uc, serror } = this.state;
      let dData = val?.length === 0 ? [] : val;
      return (
        <MultiSelect
          name={"category"}
          defaultValue={dData}
          data={uc}
          onChange={(e) => {
            setFieldValue("category", e);
            this.selectChange(e);
          }}
          className={serror || error ? "empty" : ""}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  setVal = (val) => (val?.toString()?.trim() !== "" ? val : null);
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { userData } = this.props;
      const { disData, path, usrlist } = this.state;
      if (disData?.length > 0) {
        let flag = true;
        if (path === "pcs") {
          values.userType = "PCS";
          values.uid = 68;
        } else if (path === "designated") {
          values.userType = "Designated Person";
          values.uid = 67;
        } else if (path === "compliance") {
          values.userType = "Compliance Officer";
          values.uid = 66;
        }
        values.pan = values.pan?.toUpperCase();
        let panInx = usrlist?.filter(
          (a) => a.pan?.toUpperCase() === values.pan
        );
        if (panInx?.length > 0) {
          let list = panInx.filter((b) => b.id !== values.id);
          if (list?.length > 0) {
            if (list[0].uid === values.uid) flag = true;
            else {
              if (
                list?.length > 1 ||
                (list[0].uid === 66 && values.uid === 68) ||
                (list[0].uid === 68 && values.uid === 66)
              )
                flag = true;
              else flag = false;
            }
          } else flag = false;
        } else flag = false;
        if (flag) message.error(PageConst.panErr);
        else {
          values.passport = values.passport?.toUpperCase();
          values.dpid = values.dpid?.toUpperCase();
          values.category = disData.toString();
          values.pincode = this.setVal(values.pincode);
          values.aadhar = this.setVal(values.aadhar);
          values.clientID = this.setVal(values.clientID);
          values.dateOfAppointment = this.setVal(values.dateOfAppointment);
          values.din = this.setVal(values.din);
          values.bankAccount = this.setVal(values.bankAccount);
          if (values.id) {
            if (values.dateOfAppointment === userData.dateOfAppointment)
              delete values.dateOfAppointment;
            delete values.uid;
            delete values.userType;
            this.props.updateUser(values);
          } else {
            values.cid = userId;
            this.props.addUser(values);
          }
        }
      } else this.setState({ serror: true });
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  btnUI = (link, id, txt) => (
    <Button onClick={() => this.back(link + window.btoa(id))}>{txt}</Button>
  );
  back = (link) => this.props.history.push(link);
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  render() {
    const { disable, initBasic, serror, profile, title } = this.state;
    const { loading } = this.props;
    let head = profile ? PageConst.pf : title;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{head}</h4>
              <div className="bottomDiv">
                <div className="btn">
                  {/* {id !== "" && (<><Button onClick={() => this.back("/education/" + id)}>{PageConst.ve}</Button>
                      <Button onClick={() => this.back("/employment/" + id)}>{PageConst.vme}</Button>
                      <Button onClick={() => this.back("/directorship/" + id)}>{PageConst.vd}</Button></>)} */}
                  {userRole !== 67 && (
                    <Button
                      onClick={() =>
                        profile ? this.back("/") : this.props.history.goBack()
                      }
                    >
                      {ButtonConst.back}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Formik
              initialValues={initBasic}
              validationSchema={ValidationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <div className="display">
                    <h3 className="toptl">{PageConst.bsd}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.name}
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        <Input
                          name="name"
                          value={values.name?.trim()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="1"
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        {errors.name && touched.name ? (
                          <div className="form-error">{errors.name}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.surname}
                          className={
                            errors.surname && touched.surname ? "empty" : ""
                          }
                        />
                        <Input
                          name="surname"
                          value={values.surname?.trim()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="2"
                          className={
                            errors.surname && touched.surname ? "empty" : ""
                          }
                        />
                        {errors.surname && touched.surname ? (
                          <div className="form-error">{errors.surname}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.fatherName}
                          className={
                            errors.fatherName && touched.fatherName
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="fatherName"
                          value={values.fatherName?.trim()}
                          handleChange={handleChange}
                          tabIndex="3"
                          className={
                            errors.fatherName && touched.fatherName
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.fatherName && touched.fatherName ? (
                          <div className="form-error">{errors.fatherName}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.gender}
                          className={
                            errors.gender && touched.gender ? "empty" : ""
                          }
                        />
                        {values.gender === "" &&
                          this.selectUI(
                            values.gender,
                            "gender",
                            genderList,
                            setFieldValue,
                            errors.gender && touched.gender
                          )}
                        {values.gender !== "" &&
                          this.selectUI(
                            values.gender,
                            "gender",
                            genderList,
                            setFieldValue
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.email}
                          className={
                            errors.email && touched.email ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="email"
                          value={values.email?.trim()}
                          handleChange={handleChange}
                          tabIndex="4"
                          className={
                            errors.email && touched.email ? "empty" : ""
                          }
                        />
                        {errors.email && touched.email ? (
                          <div className="form-error">{errors.email}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.mobile}
                          className={
                            errors.mobile && touched.mobile ? "empty" : ""
                          }
                        />
                        <Input
                          type="number"
                          min="1"
                          onBlur={handleBlur}
                          name="mobile"
                          value={values.mobile}
                          tabIndex="5"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "mobile", val)
                          }
                          className={
                            errors.mobile && touched.mobile ? "empty" : ""
                          }
                        />
                        {errors.mobile && touched.mobile && (
                          <div className="form-error">{errors.mobile}</div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="display">
                    <h3 className="toptl">{PageConst.ld}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.address}
                          className={
                            errors.address && touched.address ? "empty" : ""
                          }
                        />
                        <Input
                          row={4}
                          onBlur={handleBlur}
                          name="address"
                          value={values.address}
                          handleChange={handleChange}
                          tabIndex="6"
                          className={
                            errors.address && touched.address ? "empty" : ""
                          }
                        />
                        {errors.address && touched.address ? (
                          <div className="form-error">{errors.address}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.city}
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="city"
                          value={values.city}
                          handleChange={handleChange}
                          tabIndex="7"
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                        {errors.city && touched.city ? (
                          <div className="form-error">{errors.city}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.state}
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="state"
                          value={values.state}
                          handleChange={handleChange}
                          tabIndex="8"
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                        {errors.state && touched.state ? (
                          <div className="form-error">{errors.state}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pin}
                          className={
                            errors.pincode && touched.pincode ? "empty" : ""
                          }
                        />
                        <Input
                          type="number"
                          min="100000"
                          max="999999"
                          onBlur={handleBlur}
                          name="pincode"
                          value={values.pincode}
                          tabIndex="9"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "pincode", val)
                          }
                          className={
                            errors.pincode && touched.pincode ? "empty" : ""
                          }
                        />
                        {errors.pincode && touched.pincode ? (
                          <div className="form-error">{errors.pincode}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div className="display">
                    <h3 className="toptl">{PageConst.pd}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.nation}
                          className={
                            errors.nationality && touched.nationality
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="nationality"
                          value={values.nationality}
                          handleChange={handleChange}
                          tabIndex="10"
                          className={
                            errors.nationality && touched.nationality
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.nationality && touched.nationality ? (
                          <div className="form-error">{errors.nationality}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.passport}
                          className={
                            errors.passport && touched.passport ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="passport"
                          value={values.passport?.toUpperCase()}
                          handleChange={handleChange}
                          tabIndex="14"
                          className={
                            errors.passport && touched.passport ? "empty" : ""
                          }
                        />
                        {errors.passport && touched.passport && (
                          <div className="form-error">{errors.passport}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pan}
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        <Input
                          name="pan"
                          value={values.pan?.toUpperCase()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="11"
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        {errors.pan && touched.pan ? (
                          <div className="form-error">{errors.pan}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.din}
                          className={errors.din && touched.din ? "empty" : ""}
                        />
                        <Input
                          type="number"
                          min="1"
                          name="din"
                          value={values.din}
                          tabIndex="12"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "din", val)
                          }
                          onBlur={handleBlur}
                          className={errors.din && touched.din ? "empty" : ""}
                        />
                        {errors.din && touched.din ? (
                          <div className="form-error">{errors.din}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.dpid}
                          className={errors.dpId && touched.dpId ? "empty" : ""}
                        />
                        <Input
                          name="dpId"
                          value={values.dpId?.toUpperCase()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="13"
                          className={errors.dpId && touched.dpId ? "empty" : ""}
                        />
                        {errors.dpId && touched.dpId && (
                          <div className="form-error">{errors.dpId}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.aadhar}
                          className={
                            errors.aadhar && touched.aadhar ? "empty" : ""
                          }
                        />
                        <Input
                          type="number"
                          min="1"
                          onBlur={handleBlur}
                          name="aadhar"
                          value={values.aadhar}
                          tabIndex="13"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "aadhar", val)
                          }
                          className={
                            errors.aadhar && touched.aadhar ? "empty" : ""
                          }
                        />
                        {errors.aadhar && touched.aadhar && (
                          <div className="form-error">{errors.aadhar}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.client}
                          className={
                            errors.clientID && touched.clientID ? "empty" : ""
                          }
                        />
                        <Input
                          name="clientID"
                          type="number"
                          min="1"
                          value={values.clientID}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="15"
                          className={
                            errors.clientID && touched.clientID ? "empty" : ""
                          }
                        />
                        {errors.clientID && touched.clientID && (
                          <div className="form-error">{errors.clientID}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.appiotment} />
                        <DatePicker
                          value={values.dateOfAppointment}
                          handleChange={handleChange("dateOfAppointment")}
                          tabIndex="20"
                        />
                        {errors.dateOfAppointment &&
                          touched.dateOfAppointment && (
                            <div className="form-error">
                              {errors.dateOfAppointment}
                            </div>
                          )}
                      </Col>
                      {/* {!profile && (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                          <Label title={PageConst.userType} className={errors.userType && touched.userType ? "empty" : ""}/>
                          {values.userType === "" && this.selectUI(values.userType,"userType",role,setFieldValue,errors.userType && touched.userType)}
                          {values.userType !== "" && this.selectUI(values.userType,"userType",role,setFieldValue)}
                        </Col>
                      )} */}
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.category}
                          className={serror ? "empty" : ""}
                        />
                        {values.category?.length === 0 &&
                          this.selectmUI(
                            values.category,
                            setFieldValue,
                            errors.category && touched.category
                          )}
                        {values.category?.length > 0 &&
                          this.selectmUI(values.category, setFieldValue)}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.un}
                          className={
                            errors.userName && touched.userName ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="userName"
                          value={values.userName?.trim()}
                          handleChange={handleChange}
                          tabIndex="21"
                          className={
                            errors.userName && touched.userName ? "empty" : ""
                          }
                        />
                        {errors.userName && touched.userName ? (
                          <div className="form-error">{errors.userName}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="display">
                    <h3 className="toptl">{PageConst.bd}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.bank}className={errors.bankName && touched.bankName ? "empty" : ""}/>
                        <Input name="bankName"value={values.bankName}handleChange={handleChange}onBlur={handleBlur}tabIndex="16"className={errors.bankName && touched.bankName ? "empty" : ""}/>
                        {errors.bankName && touched.bankName && (<div className="form-error">{errors.bankName}</div>)}</Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.account}className={errors.bankAccount && touched.bankAccount? "empty": ""}/>
                        <Input type="number"min="1"name="bankAccount"value={values.bankAccount}tabIndex="17"onBlur={handleBlur}handleChange={(val) =>this.chnageNum(setFieldValue, "bankAccount", val)}className={errors.bankAccount && touched.bankAccount? "empty": ""}/>
                        {errors.bankAccount && touched.bankAccount && (<div className="form-error">{errors.bankAccount}</div>)}
                      </Col><Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.ifsc}className={errors.ifsc && touched.ifsc ? "empty" : ""}/>
                        <Input onBlur={handleBlur}name="ifsc"value={values.ifsc?.toUpperCase()}handleChange={handleChange}tabIndex="18"className={errors.ifsc && touched.ifsc ? "empty" : ""}/>
                        {errors.ifsc && touched.ifsc && (<div className="form-error">{errors.ifsc}</div>)}</Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}><Label title={PageConst.branch}className={errors.branch && touched.branch ? "empty" : ""}/>
                        <Input onBlur={handleBlur}name="branch"value={values.branch}handleChange={handleChange}tabIndex="19"className={errors.branch && touched.branch ? "empty" : ""}/>
                        {errors.branch && touched.branch && (<div className="form-error">{errors.branch}</div>)}</Col>
                      {values.bankName?.toString().length>0 && !errors.bankName && !touched.bankName &&values.account.toString().length>0 && !errors.account && !touched.account &&values.ifsc?.toString().length>0 && !errors.ifsc && !touched.ifsc &&
                      values.bankAccount?.toString().length>0 && !errors.bankAccount && !touched.bankAccount &&<Col xs={24} sm={24} md={24} lg={12} xl={16}>
                        <div className="btnDiv anime mt-2"><Button disabled={disable}>{ButtonConst.an}</Button></div></Col>}</Row></div> */}
                  <div className="btnDiv anime mt-1">
                    {profile && (
                      <>
                        {this.btnUI("/bank/", values.id, PageConst.vb)}
                        {this.btnUI("/education/", values.id, PageConst.ve)}
                        {this.btnUI("/employment/", values.id, PageConst.vme)}
                        {this.btnUI("/directorship/", values.id, PageConst.vd)}
                        {userRole !== 68 &&
                          this.btnUI("/holding/", values.id, PageConst.vh)}
                      </>
                    )}
                    {!profile && (
                      <Button onClick={() => this.props.history.goBack()}>
                        {ButtonConst.cancel}
                      </Button>
                    )}
                    <Button disabled={disable} htmlType={"submit"}>
                      {ButtonConst.save}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </LayOut>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  isSaved: state.user.isSaved,
  userData: state.user.userData,
  masterList: state.master.masterList,
  userList: state.user.userList,
});
const mapDispatchToProps = (dispatch) => ({
  getUserById: (payload) => dispatch(getUserById(payload)),
  addUser: (payload) => dispatch(addUser(payload)),
  updateUser: (payload) => dispatch(updateUser(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUser)
);
