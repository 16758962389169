import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  companyData: {},
  companyList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANY_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        companyList: action.payload.data,
      };
    case actions.GET_COMPANY_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_COMPANY_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        companyData: action.payload.data,
      };
    case actions.GET_COMPANY_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };

    case actions.ADD_COMPANY_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_COMPANY_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_COMPANY_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_COMPANY_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_COMPANY_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_COMPANY_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
