import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { LoginStyle } from "./style";
import { LoginConst } from "./constant";
import { login } from "redux/login/actions";
import { Label, Input, Button } from "components/Form";
import { errorHandler, errorEmpty } from "redux/app/actions";
import { getSubscriptionList } from "redux/subscription/actions";

const ValidationSchema = Yup.object().shape({
  user: Yup.string().trim().nullable().required(" "),
  password: Yup.string().trim().min(3).required(" "),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { disable: false, sList: {} };
  }
  async componentDidMount() {
    await this.props.getSubscriptionList();
  }
  async componentDidUpdate(prevProps) {
    const { subscriptionList, history } = this.props;
    if (subscriptionList !== prevProps.subscriptionList) {
      if (subscriptionList?.length === 0) history.push("/subscription");
      else if (subscriptionList?.length === 1) {
        this.setState({ sList: subscriptionList[0] });
        if (this.subUI(subscriptionList[0], true)) {
          await this.props.errorHandler({ message: LoginConst.submsg });
          await this.props.errorEmpty();
        }
      }
    }
  }
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { sList } = this.state;
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      let postdata = {
        username: values.user.trim(),
        password: values.password.trim(),
      };
      if (!this.subUI(sList)) await this.props.login(postdata);
      else {
        await this.props.errorHandler({ message: LoginConst.submsg });
        await this.props.errorEmpty();
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  dateChange = (date, format) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (format) return year + "-" + month + "-" + day;
    return date ? day + "-" + month + "-" + year : "";
  };
  subUI = (list, show) => {
    try {
      if (list?.id === 4) {
        let sdate = new Date(list.startDate);
        let edate = new Date(list.endDate);
        let today = this.dateChange(new Date(), true);
        if (new Date() >= sdate && new Date() <= edate) {
          var myDate = new Date(list.endDate);
          myDate.setDate(myDate.getDate() - 15);
          if (show && myDate <= new Date())
            message.warning(LoginConst.fmsg + this.dateChange(list.endDate));
          return false;
        }
        if (today === this.dateChange(sdate, true)) return false;
        if (today === this.dateChange(edate, true)) {
          show && message.warning(LoginConst.tmsg);
          return false;
        }
      }
      return true;
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { disable } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LoginStyle>
          {/* <Image src={coffee} alt="coffee" preview={false} className="coffee" /> */}
          <div className="box">
            {/* <Image src={logo} alt="logo" preview={false} /> */}
            <h1 className="head">{LoginConst.login}</h1>
            <Formik
              initialValues={{ user: "", password: "" }}
              validationSchema={ValidationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <div className="inputdiv login">
                    <Label
                      title={LoginConst.user}
                      className={errors.user && touched.user ? "empty" : ""}
                    />
                    <Input
                      name="user"
                      value={values.user}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={255}
                      tabIndex="1"
                      className={errors.user && touched.user ? "empty" : ""}
                    />
                    {errors.user && touched.user ? (
                      <div className="form-error">{errors.user}</div>
                    ) : null}
                  </div>
                  <div className="inputdiv">
                    <Label
                      title={LoginConst.password}
                      className={
                        errors.password && touched.password ? "empty" : ""
                      }
                    />
                    <Input
                      password
                      maxLength={25}
                      onBlur={handleBlur}
                      name="password"
                      autoComplete="new-password"
                      value={values.password}
                      handleChange={handleChange}
                      tabIndex="2"
                      className={
                        errors.password && touched.password ? "empty" : ""
                      }
                    />
                    {errors.password && touched.password ? (
                      <div className="form-error">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="btntDiv">
                    <Button htmlType="submit" disabled={disable}>
                      {LoginConst.login}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="copyRight">
            <center>
              <div className="bdiv">
                Copyright © 2023
                <a href="https://www.naapbooks.com/" target="blank">
                  Naapbooks Limited
                </a>
              </div>
            </center>
          </div>
        </LoginStyle>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.login.loading,
  loading2: state.subscription.loading,
  error: state.login.error,
  message: state.login.message,
  subscriptionList: state.subscription.subscriptionList,
});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  errorHandler: (payload) => dispatch(errorHandler(payload)),
  errorEmpty: (payload) => dispatch(errorEmpty(payload)),
  getSubscriptionList: (payload) => dispatch(getSubscriptionList(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
