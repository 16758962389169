import { message } from "antd";

import { axiosAuthGet, axiosAuthPost, fetchAuthPost } from "modules/Axios";
import { upsiInfoConst, AuditConst } from "modules/config";
import * as actions from "./constant";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId = userId ? userId : getAuthUserID();
export const getUpsiInfoList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_UPSIINFO_LIST_INITIATED });
    let response = await axiosAuthGet(upsiInfoConst.GET_UPSIINFO_LIST);
    if (response?.success) await dispatch({ type: actions.GET_UPSIINFO_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_UPSIINFO_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_UPSIINFO_LIST_ERROR, error: "Network Error" });
  }
};

export const getUpsiInfoById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_UPSIINFO_BY_ID_INITIATED });
    let response = await axiosAuthGet(upsiInfoConst.GET_UPSIINFO_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_UPSIINFO_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_UPSIINFO_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_UPSIINFO_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addUpsiInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_UPSIINFO_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    let response = await axiosAuthPost(upsiInfoConst.ADD_UPSIINFO, payload.val);
    if (response?.success) {// userId = userId ? userId : getAuthUserID();, created_date: payload.val?.created_date 
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.val?.dpId, text: "UPSI Infomation record Added" });
      await dispatch({ type: actions.ADD_UPSIINFO_SUCCESS, payload: response });
    } else dispatch({ type: actions.ADD_UPSIINFO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_UPSIINFO_ERROR, error: "Network Error" });
  }
};

export const updateUpsiInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_UPSIINFO_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    let response = await axiosAuthPost(upsiInfoConst.UPDATE_UPSIINFO + payload.val.id, payload.val);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();//, created_date: payload.val?.created_date 
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload?.val?.id, text: "UPSI Infomation record updated", otherText: JSON.stringify(payload.val) });
      await dispatch({ type: actions.UPDATE_UPSIINFO_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_UPSIINFO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_UPSIINFO_ERROR, error: "Network Error" });
  }
};

export const deleteUpsiInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_UPSIINFO_INITIATED });
    let response = await axiosAuthGet(upsiInfoConst.DELETE_UPSIINFO + payload.id);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "UPSI Infomation record deleted" });
      await dispatch({ type: actions.DELETE_UPSIINFO_SUCCESS, payload: response });
      message.success(response?.message)
    } else dispatch({ type: actions.DELETE_UPSIINFO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_UPSIINFO_ERROR, error: "Network Error" });
  }
};
