import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCompanyList } from "redux/company/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { PageConst } from "./constant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Button, Table, LayOut, Input } from "components/Form";
import { deleteHolding, getHoldingByuId } from "redux/holding/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class Holding extends Component {
  constructor(props) {
    super(props);
    this.state = { holding: [], company: [], uid: "", search: "" };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      this.props.getCompanyList();
      if (match?.params?.id) {
        await this.props.getHoldingByuId(window.atob(match?.params?.id));
        this.setState({ uid: match?.params?.id });
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { holdinguData, companyList } = this.props;
      if (holdinguData !== prevProps.holdinguData) {        
        let holding = holdinguData?.filter((a) => a.is_deleted === 0);
        this.setState({ holding });
      }
      if (companyList !== prevProps.companyList) {
        let company = companyList?.filter((a) => a.is_deleted === 0);
        this.setState({ company });
      }
    } catch (error) {
      console.log(error);
    }
  }
  redirect = (id) => this.props.history.push("/edit-holding/" + id);
  changetxt = (e) => this.setState({ search: e.target.value });
  removeData = async (id) => {
    try {
      await this.props.deleteHolding({ id: id, cid: userId });
      await this.props.getHoldingByuId(window.atob(this.state.uid));
    } catch (error) {
      console.log(error);
    }
  };
  goBack = () => {
    if (localStorage.url) this.props.history.push(localStorage.url);
    else this.props.history.goBack();
  };
  render() {
    const { holding, search, company } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.holding}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />{/* {role !== 68 && (<Button onClick={() =>this.props.history.push("/add-holding/" + uid)}> {ButtonConst.add}</Button>)} */}
                <Button onClick={() => this.goBack()}>
                  {ButtonConst.back}
                </Button>
              </div>
            </div>
            <div className="display">
              <h3>
                {PageConst.cnm}
                {company[0]?.name}
              </h3>
              <Table
                data={holding}
                type="holding"
                tab="holding"
                search={search}
                editCol={this.redirect}
                deleteCol={this.removeData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.holding.loading,
  holdingList: state.holding.holdingList,
  holdinguData: state.holding.holdinguData,
  companyList: state.company.companyList,
});
const mapDispatchToProps = (dispatch) => ({
  getCompanyList: (payload) => dispatch(getCompanyList(payload)),
  deleteHolding: (payload) => dispatch(deleteHolding(payload)),
  getHoldingByuId: (payload) => dispatch(getHoldingByuId(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Holding)
);
