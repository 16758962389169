import * as actions from "./constant";
import { push } from "connected-react-router";

export const errorHandler = (payload) => (dispatch) => {
  try {
    if (payload?.success === false) {
      let message = payload?.error?.sqlMessage
        ? payload.error.sqlMessage
        : payload?.message
          ? payload.message
          : "";
      let sp = message?.toString()?.split(".");
      if (payload?.error?.sqlMessage && sp?.length > 0) {
        sp[sp.length - 2]?.split("'");
        let lst = sp[sp.length - 2]?.split("'");
        lst?.pop();
        lst?.push(sp[sp.length - 1]);
        message = lst?.toString()?.replace(/,/g, "")?.replace(/'/g, "");
      }
      dispatch({ type: actions.ERROR_HANDLER, payload: message });
    }
  } catch (error) {
    console.log(error, "Error Handler");
  }
};
export const errorEmpty = () => (dispatch) => {
  dispatch({ type: actions.ERROR_EMPTY });
};
export const redirectLogin = () => async (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  await dispatch({ type: actions.REDIRECT_LOGIN });
  dispatch(push("/"));
};
export const loader = (payload) => (dispatch) => {
  try {
    dispatch({ type: actions.LOADER_STATUS, payload });
  } catch (error) {
    console.log("loader:", error);
  }
};
export const menuCol = (payload) => (dispatch) => {
  try {
    dispatch({ type: actions.MENUCOL, payload });
  } catch (error) {
    console.log("loader:", error);
  }
};
