import React, { Component } from "react";
import { Spin, Divider } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, LayOut, Button, Label } from "components/Form";
import { getUpsiInfoById } from "redux/upsiInfo/actions";
import { getClosureList } from "redux/closure/actions";
import { getAuthUserID, getAuthRole } from "modules/helper";
import { PageConst } from "container/AddUPSIInfo/constant";
import { appConst, ButtonConst } from "App/AppConstant";
import { configVar } from "modules/config";
var role = getAuthRole();
var userId = getAuthUserID();
class ApproveUPSIInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { disable: false, cList: [], id: 0, data: { id: 0 } };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      if (match?.path?.toLowerCase().includes("/approve-upsi")) {
        await this.props.getUpsiInfoById(window.atob(match?.params?.id));
        this.setState({ id: window.atob(match?.params?.id) });
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { upsiInfoData, closureList } = this.props;
      if (upsiInfoData !== prevProps.upsiInfoData) {
        this.setState({ data: upsiInfoData });
        if (upsiInfoData?.approve) this.props.getClosureList();
      }
      if (prevProps.closureList !== closureList) {
        const { id } = this.state;
        let ml = closureList?.filter(
          (a) => a.is_deleted === 0 && a.upsiId === id
        );
        this.setState({ cList: ml });
      }
    } catch (error) {
      console.log(error);
    }
  }
  lblUI = (txt, txt2, a) => {
    try {
      if (txt?.charAt(txt.length - 1) === "*")
        txt = txt.substring(0, txt.length - 1);
      if (txt2)
        return (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={a ? a : 12}
            className="anime"
          >
            <Label title={txt + " : "} />
            <h3>{txt2}</h3>
          </Col>
        );
    } catch (error) {
      console.log(error);
    }
  };
  submit = async (a) =>
    this.props.history.push("/add-window-closure/" + window.btoa(a));
  dateChange = (date) => {
    try {
      let day = new Date(date)?.getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date)?.getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date)?.getFullYear();
      return date ? day + "-" + month + "-" + year : "";
    } catch (error) {
      console.log(error);
    }
  };
  setDate = (date) =>
    this.dateChange(date) + " " + new Date(date).toLocaleTimeString();
  render() {
    const { loading, history } = this.props;
    const { disable, data, cList, id } = this.state;
    if (data?.sdate) {
      data.timeStamp = this.setDate(data.created_date);
      data.UPSIDate = this.setDate(data.sdate);
    }
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.aul}</h4>
              <Button onClick={() => history.push("/upsi-information")}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              {data?.id && (
                <Row gutter={40}>
                  {this.lblUI(PageConst.ud, data.timeStamp)}
                  {this.lblUI(PageConst.usd, data.UPSIDate)}
                  {this.lblUI(PageConst.type, data.type)}
                  {/* {this.lblUI(PageConst.sn, data.dpName)} */}
                  {/* {this.lblUI(PageConst.receiver, data.receiver)}} */}
                  {data?.isInfoSend?.data[0] === 1
                    ? this.lblUI(PageConst.sn, data.receiver)
                    : this.lblUI(PageConst.sn, data.dpName)}
                  {data?.isInfoSend?.data[0] === 1
                    ? this.lblUI(PageConst.receiver, data.dpName)
                    : this.lblUI(PageConst.receiver, data.receiver)}
                  {this.lblUI(PageConst.purpose, data.purpose)}
                  {this.lblUI(PageConst.mode, data.mode)}
                  {this.lblUI(
                    PageConst.doc,
                    data.documentName?.length > 0 ? (
                      <a
                        href={configVar.BASE_URL + "files/" + data.documentName}
                        download={data.documentName}
                      >
                        {data.documentName}
                      </a>
                    ) : null
                  )}
                  {/* {this.lblUI(PageConst.desc, data.description, true)} configVar.BASE_URL+"files/"+data.documentName*/}
                  <Col xs={24} sm={24} md={24} lg={12} xl={8} className="anime">
                    <Label title={PageConst.desc + " : "} />
                    <p>{data.description}</p>
                  </Col>
                  {data?.approve ? (
                    cList?.length > 0 ? (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="anime"
                      >
                        <Divider orientation="left">
                          <h2 className="title">{PageConst.twc}</h2>
                        </Divider>
                      </Col>
                    ) : (
                      this.lblUI(ButtonConst.clos + ButtonConst.added, "Yes")
                    )
                  ) : (
                    ""
                  )}
                  {data?.approve
                    ? cList?.map((a, i) => (
                        <>
                          {this.lblUI(PageConst.connected, a.Selection)}
                          {this.lblUI(PageConst.from, this.dateChange(a.from))}
                          {this.lblUI(PageConst.to, this.dateChange(a.to))}
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            className="anime"
                          >
                            <Label title={PageConst.purpose + " : "} />
                            <p
                              className={data?.length - 1 === i ? "" : "dashb"}
                            >
                              {a.closureReason}
                            </p>
                          </Col>
                        </>
                      ))
                    : ""}
                </Row>
              )}
              {role === 66 && !data?.approve && (
                <div className="btnDiv anime">
                  <Button disabled={disable} onClick={() => this.submit(id)}>
                    {ButtonConst.add + ButtonConst.clos}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </LayOut>
        {/* {visible && <Modal upsi toggleModal={this.toggleModal}  setReject={this.setReject}/>} */}
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.upsiInfo.loading,
  isSaved: state.upsiInfo.isSaved,
  upsiInfoData: state.upsiInfo.upsiInfoData,
  closureList: state.closure.closureList,
});
const mapDispatchToProps = (dispatch) => ({
  getUpsiInfoById: (payload) => dispatch(getUpsiInfoById(payload)),
  getClosureList: (payload) => dispatch(getClosureList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApproveUPSIInfo)
);
