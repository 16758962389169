import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { employmentConst,AuditConst } from "modules/config";
import * as actions from "./constant";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId =userId?userId: getAuthUserID();
export const getEmploymentList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_EMPLOYMENT_LIST_INITIATED });
    let response = await axiosAuthGet(employmentConst.GET_EMPLOYMENT_LIST);
    if (response?.success)
      await dispatch({type: actions.GET_EMPLOYMENT_LIST_SUCCESS,payload: response});
    else dispatch({type: actions.GET_EMPLOYMENT_LIST_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({type: actions.GET_EMPLOYMENT_LIST_ERROR,error: "Network Error"});
  }
};

export const getEmploymentById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_EMPLOYMENT_BY_ID_INITIATED });
    let response = await axiosAuthGet(employmentConst.GET_EMPLOYMENT_BY_ID + id);
    if (response?.success)
      await dispatch({type: actions.GET_EMPLOYMENT_BY_ID_SUCCESS,payload: response});
    else dispatch({type: actions.GET_EMPLOYMENT_BY_ID_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({type: actions.GET_EMPLOYMENT_BY_ID_ERROR,error: "Network Error"});
  }
};

export const addEmployment = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_EMPLOYMENT_INITIATED });
    let response = await axiosAuthPost(employmentConst.ADD_EMPLOYMENT, payload);
    if (response?.success){
      userId = userId ? userId : getAuthUserID();
      await dispatch({type: actions.ADD_EMPLOYMENT_SUCCESS,payload: response});
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:userId,text:"Employment added"});}
      else dispatch({ type: actions.ADD_EMPLOYMENT_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_EMPLOYMENT_ERROR, error: "Network Error" });
  }
};

export const updateEmployment = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_EMPLOYMENT_INITIATED });
    let response = await axiosAuthPost(employmentConst.UPDATE_EMPLOYMENT + payload.id,payload);
    if (response?.success){
      userId = userId ? userId : getAuthUserID();
      await dispatch({type: actions.UPDATE_EMPLOYMENT_SUCCESS,payload: response});
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:userId,toid:payload.id,text:"Employment update"});}
      else dispatch({type: actions.UPDATE_EMPLOYMENT_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_EMPLOYMENT_ERROR, error: "Network Error" });
  }
};

export const deleteEmployment = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_EMPLOYMENT_INITIATED });
    let response = await axiosAuthGet(employmentConst.DELETE_EMPLOYMENT + id);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await dispatch({type: actions.DELETE_EMPLOYMENT_SUCCESS,payload: response});
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:userId,toid:id,text:"Employment delete"});
    } else dispatch({type: actions.DELETE_EMPLOYMENT_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_EMPLOYMENT_ERROR, error: "Network Error" });
  }
};
