import React, { Component } from "react";
import { Checkbox } from "antd";
import { StyleContainer } from "./style";

export default class Check extends Component {
  render() {
    const { text, onChange, checked, className } = this.props;
    return (
      <StyleContainer className={className ? className : ""}>
        <Checkbox onChange={onChange} checked={checked}>
          {text}
        </Checkbox>
      </StyleContainer>
    );
  }
}
Check.defaultProps = {
  checked: false,
};
