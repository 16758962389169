import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { educationConst, AuditConst } from "modules/config";
import * as actions from "./constant";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId = userId ? userId : getAuthUserID()
export const getEducationList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_EDUCATION_LIST_INITIATED });
    let response = await axiosAuthGet(educationConst.GET_EDUCATION_LIST);
    if (response?.success)
      await dispatch({ type: actions.GET_EDUCATION_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_EDUCATION_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_EDUCATION_LIST_ERROR, error: "Network Error" });
  }
};

export const getEducationById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_EDUCATION_BY_ID_INITIATED });
    let response = await axiosAuthGet(educationConst.GET_EDUCATION_BY_ID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_EDUCATION_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_EDUCATION_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_EDUCATION_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addEducation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_EDUCATION_INITIATED });
    let response = await axiosAuthPost(educationConst.ADD_EDUCATION, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, text: "Education added" });
      await dispatch({ type: actions.ADD_EDUCATION_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_EDUCATION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_EDUCATION_ERROR, error: "Network Error" });
  }
};

export const updateEducation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_EDUCATION_INITIATED });
    let response = await axiosAuthPost(educationConst.UPDATE_EDUCATION + payload.id, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "Education update" });
      await dispatch({ type: actions.UPDATE_EDUCATION_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_EDUCATION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_EDUCATION_ERROR, error: "Network Error" });
  }
};

export const deleteEducation = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_EDUCATION_INITIATED });
    let response = await axiosAuthGet(educationConst.DELETE_EDUCATION + id);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: id, text: "Education delete" });
      await dispatch({ type: actions.DELETE_EDUCATION_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETE_EDUCATION_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_EDUCATION_ERROR, error: "Network Error" });
  }
};
