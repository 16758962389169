import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getConRefList } from "redux/connRef/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Button, Table, LayOut, Input } from "components/Form";
import { getDisclosureList, deleteDisclosure } from "redux/disclosure/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class Disclosure extends Component {
  constructor(props) {
    super(props);
    this.state = { ulist: [], usrlist: [] };
  }
  async componentDidMount() {
    try {
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      await this.props.getConRefList();
      await this.props.getDisclosureList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { disclosureList, connRefList } = this.props;
      if (disclosureList !== prevProps.disclosureList) {
        let ml = disclosureList?.filter((a) => a.is_deleted === 0);
        const { usrlist } = this.state;
        let ml2 = [];
        if (role === 67) {
          ml = ml?.filter((a) => a.uid === userId);
          usrlist?.forEach((s) => {
            let cl = disclosureList?.filter(
              (a) => a.is_deleted === 0 && a.cid?.toString() === s?.toString()
            );
            cl?.forEach((f) => {
              if (
                ml2?.findIndex((d) => d.id === f.id) === -1 &&
                ml?.findIndex((d) => d.id === f.id) === -1
              )
                ml2.push(f);
            });
          });
        }
        let allcl = ml.concat(ml2);
        let sortData = allcl?.sort((a, b) => b.id - a.id);
        this.setState({ ulist: sortData });
      }
      if (connRefList !== prevProps.connRefList) {
        let list = [];
        connRefList?.filter(
          (a) => a.dpId === userId && a.is_deleted === 0 && list.push(a.uId)
        );
        this.setState({ usrlist: list });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      await this.props.deleteDisclosure({ id: id, uid: userId });
      await this.props.getDisclosureList();
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { ulist, search } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.dl}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role === 67 && (
                  <Button
                    onClick={() => this.props.history.push("/add-disclosures")}
                  >
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={ulist}
                search={search}
                type="disclosure"
                tab="disclosure"
                deleteCol={this.removeData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.disclosure.loading,
  disclosureList: state.disclosure.disclosureList,
  connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  getDisclosureList: (payload) => dispatch(getDisclosureList(payload)),
  deleteDisclosure: (payload) => dispatch(deleteDisclosure(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Disclosure)
);
