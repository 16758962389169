import axios from "axios";
import store from "redux/store";
import { configVar } from "modules/config";
import { errorHandler, errorEmpty } from "redux/app/actions";
import { logout } from "redux/login/actions";
import { message } from "antd";

export const getTime = () => {
  try {
    let auth = localStorage.auth && JSON.parse(localStorage.auth);
    let date = auth && auth.prj === "upsi" && auth.date;
    if (date) {
      var oldDateObj = new Date(date);
      var newDateObj = new Date(date);
      newDateObj.setTime(oldDateObj.getTime() + 10 * 60 * 1000);
      let res = new Date(newDateObj) > new Date();
      if (!res) store.dispatch(logout());
      else {
        auth.date = new Date();
        localStorage.auth = JSON.stringify(auth);
      }
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
export const axiosPost = async (url, data) => {
  try {
    let { data: response } = await axios.post(configVar.BASE_URL + url, data);
    if (!response || !response?.success) store.dispatch(errorHandler(response));
    store.dispatch(errorEmpty());
    return response;
  } catch (error) {
    store.dispatch(errorHandler("Network Error"));
    console.log(error);
  }
};
export const axiosGet = async (url) => {
  try {
    let { data: response } = await axios.get(configVar.BASE_URL + url);
    if (!response || !response?.success) store.dispatch(errorHandler(response));
    store.dispatch(errorEmpty());
    return response;
  } catch (error) {
    store.dispatch(errorHandler("Network Error"));
    console.log(error);
  }
};
export const axiosAuthGet = async (url) => {
  try {
    if (getTime()) {
      let { data: response } = await axios.get(configVar.BASE_URL + url);
      if (!response || !response?.success)
        store.dispatch(errorHandler(response));
      store.dispatch(errorEmpty());
      return response;
    }
  } catch (error) {
    store.dispatch(errorHandler("Network Error"));
    console.log(error);
  }
};
export const axiosAuthPost = async (url, payload) => {
  try {
    if (getTime()) {
      let { data: response } = await axios.post(
        configVar.BASE_URL + url,
        payload
      );
      if (!response || !response?.success)
        store.dispatch(errorHandler(response));
      store.dispatch(errorEmpty());
      if (
        response?.success &&
        !url.includes("education") &&
        !url.includes("director") &&
        !url.includes("bank") &&
        !url.includes("holding") &&
        !url.includes("auditlogs") &&
        !url.includes("employment")
      )
        response?.message?.length > 0 && message.success(response?.message);
      return response;
    }
  } catch (error) {
    store.dispatch(errorHandler("Network Error"));
    console.log(error);
  }
};
export const fetchAuthPost = async (payload) => {
  try {
    let res;
    await fetch(configVar.BASE_URL + "upload", {
      method: "POST",
      body: payload,
      redirect: "manual",
    })
      .then((response) => response.json())
      .then((result) => (res = result))
      .catch((error) => console.log("error", error));
    return res;
  } catch (error) {
    store.dispatch(errorHandler("Network Error"));
    console.log(error);
  }
};
