import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { ButtonConst, FormValid } from "App/AppConstant";
import { getMasterList } from "redux/master/actions";
import {
  addHolding,
  getHoldingById,
  updateHolding,
} from "redux/holding/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
} from "components/Form";
import { getAuthUserID, getAuthUserName } from "modules/helper";
var userId = getAuthUserID();
var userName = getAuthUserName();
const ValidationSchema = Yup.object().shape({
  securityName: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ0-9\s]+$/, FormValid.charValid),
  security: Yup.string().trim().nullable().required(" "),
  securityNo: Yup.number().nullable().required(" ").test("Is positive?", " ", (value) => value > 0),//.matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  holdingHeld: Yup.number().nullable().required(" ").test("Is positive?", " ", (value) => value > 0),//.matches(/^[aA-zZ0-9.\s]+$/, FormValid.digitsValid),
});

class UPSIInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      edit: false,
      ulist: [],
      init: {
        uId: 0,
        securityName: "",
        security: "",
        securityNo: "",
        holdingHeld: "",
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      userName = userName ? userName : getAuthUserName();
      await this.props.getMasterList();
      if (match?.path?.toLowerCase().includes("edit-holding")) {
        await this.props.getHoldingById(window.atob(match?.params?.id));
        this.setState({ edit: true });
      } else if (match?.params?.id) {
        const { init } = this.state;
        init.uId = window.atob(match?.params?.id);
        this.setState({ init });
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { holdingData, masterList, isSaved, isUpdated } = this.props;
      if (isSaved !== prevProps.isSaved)
        if (isSaved) {
          message.success("Holding added");
          this.props.history.goBack();
        }
      if (isUpdated !== prevProps.isUpdated)
        if (isUpdated) {
          message.success("Holding Updated");
          this.props.history.goBack();
        }
      if (masterList !== prevProps.masterList) {
        let list = masterList.filter((a) => a.typeName === "Security Type");
        list.forEach((b) => (b.value = b.mname));
        this.setState({ ulist: list });
      }
      if (holdingData !== prevProps.holdingData) {
        let data = {
          id: holdingData.id,
          uId: holdingData.uId,
          securityName: holdingData.securityName,
          security: holdingData.security,
          securityNo: holdingData.securityNo,
          holdingHeld: holdingData.holdingHeld,
        };
        this.setState({ init: data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, setFieldValue, error) => {
    try {
      const { ulist } = this.state;
      let data = ulist;
      return (
        <Select
          withID
          name={"security"}
          data={data}
          value={val}
          defaultValue={val}
          className={error && val === "" ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue("security", value);
            setFieldValue("securityType", data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      values.cid = userId;
      if (values.id) this.props.updateHolding(values);
      else await this.props.addHolding(values);
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    if (name === "holdingHeld" && parseInt(txt) > 100) {
    } else setFieldValue(name, txt);
  };
  render() {
    const { init, disable, edit } = this.state;
    const { loading, loading2 } = this.props;
    return (
      <Spin spinning={loading || loading2} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">
                {edit ? PageConst.ehold : PageConst.hold}
              </h4>
              <Button onClick={() => this.props.history.goBack()}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.scn}
                          className={
                            errors.securityName && touched.securityName
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          name="securityName"
                          value={values.securityName}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="1"
                          className={
                            errors.securityName && touched.securityName
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.securityName && touched.securityName ? (
                          <div className="form-error">
                            {errors.securityName}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.tos}
                          className={
                            errors.security && touched.security ? "empty" : ""
                          }
                        />
                        {values.security === "" &&
                          this.selectUI(
                            values.security,
                            setFieldValue,
                            errors.security && touched.security
                          )}
                        {values.security !== "" &&
                          this.selectUI(values.security, setFieldValue)}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.sh}
                          className={
                            errors.securityNo && touched.securityNo
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          name="securityNo"
                          type="number"
                          min="1"
                          value={values.securityNo}
                          onBlur={handleBlur}
                          tabIndex="3"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "securityNo", val)
                          }
                          className={
                            errors.securityNo && touched.securityNo
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.tl}
                          className={
                            errors.holdingHeld && touched.holdingHeld
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          min="1"
                          name="holdingHeld"
                          value={values.holdingHeld}
                          onBlur={handleBlur}
                          tabIndex="4"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "holdingHeld", val)
                          }
                          className={
                            errors.holdingHeld && touched.holdingHeld
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.holdingHeld && touched.holdingHeld ? (
                          <div className="form-error">{errors.holdingHeld}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button onClick={() => this.props.history.goBack()}>
                        {ButtonConst.cancel}
                      </Button>
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.master.loading,
  masterList: state.master.masterList,
  loading2: state.holding.loading,
  isSaved: state.holding.isSaved,
  isUpdated: state.holding.isUpdated,
  holdingData: state.holding.holdingData,
});
const mapDispatchToProps = (dispatch) => ({
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  addHolding: (payload) => dispatch(addHolding(payload)),
  updateHolding: (payload) => dispatch(updateHolding(payload)),
  getHoldingById: (payload) => dispatch(getHoldingById(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UPSIInfo)
);
