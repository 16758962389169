import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, Modal, message } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

import { form } from "components/Images";
import { sendmail } from "redux/smtp/actions";
import { getUserList } from "redux/user/actions";
import { getMasterList } from "redux/master/actions";
import { getConRefList } from "redux/connRef/actions";
import { getClosureList } from "redux/closure/actions";
import { getHoldingByuId } from "redux/holding/actions";
import { ButtonConst, FormValid } from "App/AppConstant";
import { PageConst, PurConst, Holidays } from "./constant";
import { getAuthUserID, getAuthRole } from "modules/helper";
import { getTemplateInfoList } from "redux/templates/actions";
import {
  getTradingList,
  addTrading,
  getTradingById,
  updateTrading,
} from "redux/trading/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
  DatePicker,
  Checkbox,
  FileUpload,
} from "components/Form";

var userId = getAuthUserID();
var role = getAuthRole();

const ValidationSchema = Yup.object().shape({
  connected: Yup.string().nullable().required(" "),
  purpose: Yup.string().nullable().required(" "),
  connectedID: Yup.string().nullable().matches(/^[0-9\s]+$/, FormValid.charValid),
  // clientID: Yup.string().nullable().matches(/^[0-9\s]+$/, FormValid.charValid),
  // pDateMax: Yup.string().nullable().required(" ").matches(/^[0-9\s]+$/, FormValid.charValid),
  securityNo: Yup.number().nullable().required(" ").test("Is positive?", " ", (value) => value > 0), //.matches(/^[0-9\s]+$/, FormValid.charValid),
  transPrice: Yup.number().nullable().required(" ").test("Is positive?", " ", (value) => value > 0), //.matches(/^[0-9\s]+$/, FormValid.charValid),
  currentPrice: Yup.string().nullable().matches(/^[0-9\s]+$/, FormValid.charValid),
  newDate: Yup.date().nullable(),
  startDate: Yup.date().nullable().required(" "), //.when("newDate", (newDate, schema) => schema.max(new Date((new Date()).getTime() + (60*1000*24*60*10)), PageConst.smDate)),
  endDate: Yup.date()
    .nullable()
    .required(" ")
    .when(
      "startDate",
      (startDate, schema) => startDate && schema.min(startDate, PageConst.mDate)
    ),
  // .when("startDate",(startDate, schema) =>startDate &&schema.max(new Date(startDate.getTime() + 60 * 1000 * 24 * 60 * 11),PageConst.mdDate)),
});
class AddTrading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      disable: false,
      id: 0,
      holding: 0,
      docnm: "",
      docByte: "",
      ulist: [],
      clist: [],
      tempMail: [],
      tradList: [],
      closrList: [],
      init: {
        connectedID: null,
        connected: "", // clientID: null,client: "",
        purpose: "", // oldApp: null,rPurpose: "",
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        securityNo: "", //pDateMax: null,
        transPrice: null,
        currentPrice: null,
        transaction: "",
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getClosureList(); //if (role === 67)
      await this.props.getConRefList(userId);
      await this.props.getUserList();
      await this.props.getTradingList();
      await this.props.getTemplateInfoList();
      if (match?.path?.toLowerCase().includes("/edit-trading/")) {
        await this.props.getTradingById(window.atob(match?.params?.id));
        this.setState({ edit: true, id: window.atob(match?.params?.id) });
      }
      await this.props.getMasterList(); // await this.props.getTradingList();
      this.checkHDate();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const {
        td,
        userList,
        connRefList,
        closureList,
        tradingList,
        templateList,
      } = this.props;
      // if (prevProps.tradingList !== tradingList) {const { id } = this.state;
      //   let ml = tradingList.filter((a) => a.is_deleted === 0 && a.id.toString() !== id);
      //   ml?.forEach((a) => (a.value = a.id.toString()));this.setState({ oldc: ml });}
      if (prevProps.closureList !== closureList) {
        let ml = closureList?.filter((a) => a.is_deleted === 0);
        this.setState({ closrList: ml });
      }
      if (td !== prevProps.td) {
        let val = {
          id: td.id,
          connectedID: td.connectedID,
          connected: td.connected, // clientID: td.clientID,// client: td.client,
          purpose: td.purpose, // oldApp: td.oldApp,rPurpose: td.rPurpose,
          startDate: new Date(td.startDate),
          endDate: new Date(td.endDate),
          securityNo: td.securityNo, // pDateMax: td.pDateMax,
          transPrice: td.transPrice,
          currentPrice: td.currentPrice,
        };
        this.setState({ init: val, checked: td.transaction === "Off Market" });
        await this.props.getHoldingByuId(td.connectedID);
      }
      if (connRefList !== prevProps.connRefList) {
        let clist = connRefList.filter((c) => c.is_deleted === 0); // c.dpId === userId &&
        this.setState({ clist });
      }
      if (userList !== prevProps.userList) {
        const { clist } = this.state;
        let urlist = userList.filter((a) => a.is_deleted === 0 && a.uid !== 68);
        let data = [],
          rids = [],
          uslist = []; // if (role === 67) {   }
        let uhlist = userList?.filter((a) => a.id === userId);
        let usr = userList?.find(
          (s) => s.id !== userId && uhlist[0].pan === s.pan
        );
        let conList = clist?.filter(
          (f) => f.dpId === userId || f.dpId === usr?.id
        );
        conList?.forEach((l) => {
          let s = userList?.find((a) => a.id === l.uId && a.uid === 69);
          if (s) uslist.push(s);
        });
        urlist = uhlist.concat(uslist);
        urlist?.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
                ? " (DP"
                : a.uid === 68
                  ? " (PCS"
                  : " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
          rids.push(a.id);
        });
        this.setState({ ulist: data, rids });
      }
      const { holdinguData } = this.props;
      if (holdinguData !== prevProps.holdinguData) {
        let holding = holdinguData?.filter((a) => a.is_deleted === 0);
        this.setState({ holding: holding[0] ? holding[0].securityNo : 0 });
      }
      if (prevProps.tradingList !== tradingList) {
        let tList = tradingList?.filter((a) => a.is_deleted === 0);
        this.setState({ tradList: tList });
      }
      if (templateList !== prevProps.templateList)
        this.setState({ tempMail: templateList });
    } catch (error) {
      console.log(error);
    }
  }
  checkHDate = () => {
    try {
      const { init } = this.state;
      if (this.disabledHDate(init.startDate)) init.startDate = "";
      if (this.disabledHDate(init.endDate)) init.endDate = "";
      this.setState({ init });
    } catch (error) {
      console.log(error);
    }
  };
  disabledHDate = (current) => {
    try {
      let match = Holidays?.find(
        (a) => new Date(a)?.toDateString() === new Date(current)?.toDateString()
      );
      return (
        match ||
        new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6
      );
    } catch (error) {
      console.log(error);
    }
  };
  onChange = (a) => {
    const { checked } = this.state;
    if (checked !== a) this.setState({ checked: a });
  };
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  seByte = (byteCode, name) =>
    this.setState({ docByte: byteCode, docnm: name });
  selectUI = (val, name, setFieldValue, error) => {
    try {
      const { ulist } = this.state;
      let data = name === "connected" ? ulist : [];
      if (name === "purpose") data = PurConst;
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          defaultValue={val}
          className={error ? "empty" : ""}
          showSearch={name === "purpose" ? "No" : ""}
          onChange={(value, data) => {
            setFieldValue(name, value);
            if (name === "connected") {
              setFieldValue("connectedID", data.id);
              this.props.getHoldingByuId(data.id);
            }
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  getBusinessDatesCount = (startDate, endDate) => {
    try {
      if (startDate && endDate) {
        let count = 0;
        const curDate = new Date(startDate?.getTime());
        while (curDate <= endDate) {
          const dayOfWeek = curDate.getDay();
          if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
          curDate.setDate(curDate.getDate() + 1);
        }
        Holidays.forEach((c) => {
          if (new Date(c) >= startDate && new Date(c) <= endDate)
            count = count - 1;
        });
        return count;
      }
      return 9;
    } catch (error) {
      console.log(error);
    }
  };
  dChange = (date) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    return date ? month + "-" + day + "-" + year : "";
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { closrList, tradList, docnm, docByte, holding } = this.state;
      if (!values.currentPrice) values.currentPrice=null;
      if (holding === 0 && values.purpose === "Sale")
        this.warnModal(PageConst.holdmsg);
      else if (values.securityNo > holding && values.purpose === "Sale")
        this.warnModal(PageConst.holdmsg2);
      else if (docnm === "") message.error(PageConst.pfu);
      else {
        let list = closrList?.filter(
          (d) =>
            d?.sid?.includes(values.connectedID) ||
            d.upid === values.connectedID ||
            (!d.upid && d.uid === values.connectedID)
        );
        let flag = false,
          froma = "",
          toa = "";
        let startDate = new Date(this.dChange(values.startDate));
        let endDate = new Date(this.dChange(values.endDate));
        let sDate = startDate?.getTime();
        let eDate = endDate?.getTime();
        let same = tradList?.filter(
          (a) => a.connectedID === values.connectedID
        );
        if (same?.length > 0) {
          for (let i = 0; i < same?.length; i++) {
            if (values.id !== same[i].id) {
              let sfrom = new Date(this.dChange(same[i]?.startDate))?.getTime();
              let sto = new Date(this.dChange(same[i]?.endDate))?.getTime();
              if (
                (sfrom <= sDate &&
                  sDate >= sto &&
                  sfrom <= eDate &&
                  sto >= eDate) ||
                (sfrom <= sDate && sDate <= sto) ||
                (sfrom <= eDate && eDate <= sto) ||
                (sfrom >= sDate && sto <= eDate)
              ) {
                flag = true;
                froma = same[i]?.startDate;
                toa = same[i]?.endDate;
                break;
              }
            }
          }
        }
        if (!flag) {
          for (let i = 0; i < list?.length; i++) {
            let from = new Date(this.dChange(list[i]?.from))?.getTime(); //?.split("T")[0]
            let to = new Date(this.dChange(list[i]?.to))?.getTime(); //?.split("T")[0]
            if (
              (from <= sDate && from <= eDate && sDate >= to && to >= eDate) ||
              (from <= sDate && sDate <= to) ||
              (from <= eDate && eDate <= to) ||
              (from >= sDate && to <= eDate)
            ) {
              flag = true;
              froma = list[i]?.from;
              toa = list[i]?.to;
              break;
            }
          }
          if (!flag) {
            let numOfDates = this.getBusinessDatesCount(
              new Date(values.startDate),
              new Date(values.endDate)
            );
            if (numOfDates < 8) {
              const { checked } = this.state;
              const { td } = this.props;// if (!values.clientID) values.clientID = userId;// values.oldApp = values.oldApp.replace("Application No. ", "");
              values.transaction = checked ? "Off Market" : "Stock Market";
              values.uid = userId;
              values.form = docnm;
              values.data = docByte;
              if (role === 66 && !values.id) {
                values.type = "Approved";
                this.sendToUser(values.connectedID);
              }
              if (values.id) {
                startDate?.toUTCString() ===
                  new Date(td.startDate)?.toUTCString() &&
                  delete values.startDate; // values.type = "Pending";
                endDate?.toUTCString() ===
                  new Date(td.endDate)?.toUTCString() && delete values.endDate;
                this.props.updateTrading(values);
              } else await this.props.addTrading(values);
            } else this.setState({ serror: true });
          } else
            this.warnModal(
              PageConst.msg +
              this.dateChange(froma) +
              " to " +
              this.dateChange(toa)
            );
        } else
          this.warnModal(
            PageConst.msg +
            this.dateChange(froma) +
            " to " +
            this.dateChange(toa)
          );
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  dateChange = (date) => {
    try {
      let day = new Date(date).getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date).getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date).getFullYear();
      return date ? day + "-" + month + "-" + year : "";
    } catch (error) {
      console.log(error);
    }
  };
  sendToUser = (a) => {
    try {
      const { userList } = this.props;
      let user = userList?.find((x) => x.id === a);
      if (user) this.sendMail(user.email);
    } catch (error) {
      console.log(error);
    }
  };
  sendMail = async (toData) => {
    try {
      const { tempMail } = this.state;
      if (toData && tempMail[0]) {
        let temp = tempMail[0]?.template;
        let mailData = { to: toData, subject: tempMail[0]?.name, html: temp };
        let postData = {
          uid: userId,
          mail: mailData,
          text: { type: tempMail[0]?.name, send: toData },
        };
        await this.props.sendmail(postData);
      } else message.error(PageConst.ce);
    } catch (error) {
      console.log(error);
    }
  };
  warnModal = async (all) => {
    try {
      Modal.warning({
        title: PageConst.title,
        content: all,
        getContainer: () => document.getElementById("App"),
        onOk: () => { },
      });
    } catch (error) {
      console.log(error);
    }
  };
  setDate = (name, date, setFieldValue, startDate) => {
    this.setState({ serror: false });
    setFieldValue(name, date);
    if (name === "endDate") {
      let numOfDates = this.getBusinessDatesCount(
        new Date(startDate),
        new Date(date)
      );
      if (numOfDates > 7) this.setState({ serror: true });
    }
  };
  render() {
    const { init, disable, checked, serror, holding, docByte, docnm } =
      this.state;
    const { loading, loading1 } = this.props;
    return (
      <Spin spinning={loading || loading1} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.title}</h4>
              <Button onClick={() => this.props.history.push("/trading")}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.person}
                          className={
                            errors.connected && touched.connected ? "empty" : ""
                          }
                        />
                        {values.connected === "" &&
                          this.selectUI(
                            values.connected,
                            "connected",
                            setFieldValue,
                            errors.connected && touched.connected
                          )}
                        {values.connected !== "" &&
                          this.selectUI(
                            values.connected,
                            "connected",
                            setFieldValue
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.total} />
                        <Input
                          disabled
                          min="1"
                          type="number"
                          name="totalHeld"
                          value={holding}
                          onBlur={handleBlur}
                          tabIndex="2"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.purpose}
                          className={
                            errors.purpose && touched.purpose ? "empty" : ""
                          }
                        />
                        {values.purpose === "" &&
                          this.selectUI(
                            values.purpose,
                            "purpose",
                            setFieldValue,
                            errors.purpose && touched.purpose
                          )}
                        {values.purpose !== "" &&
                          this.selectUI(
                            values.purpose,
                            "purpose",
                            setFieldValue
                          )}
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.oldApp}
                          className={errors.oldApp && touched.oldApp ? "empty" : ""}/>
                        {values.oldApp === "" &&
                          this.selectUI(values.oldApp,"oldApp",setFieldValue,errors.oldApp && touched.oldApp)}
                        {values.oldApp !== "" && this.selectUI(values.oldApp, "oldApp", setFieldValue)}
                      </Col> 
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.rPurpose}className={errors.rPurpose && touched.rPurpose ? "empty" : ""}/>
                        <Input name="rPurpose"value={values.rPurpose}onBlur={handleBlur}handleChange={handleChange}
                          tabIndex="1"className={errors.rPurpose && touched.rPurpose ? "empty" : ""}/>
                      </Col>*/}
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} className="">
                        <Label
                          title={PageConst.startDate}
                          className={
                            errors.startDate && touched.startDate ? "empty" : ""
                          }
                        />
                        <DatePicker
                          holiday
                          value={values.startDate} // handleChange={handleChange("startDate")}
                          handleChange={(e) =>
                            this.setDate("startDate", e, setFieldValue)
                          }
                          tabIndex="2"
                          className={
                            errors.startDate && touched.startDate ? "empty" : ""
                          }
                        />
                        {errors.startDate && touched.startDate ? (
                          <div className="form-error">{errors.startDate}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} className="">
                        <Label
                          title={PageConst.endDate}
                          className={
                            (errors.endDate && touched.endDate) || serror
                              ? "empty"
                              : ""
                          }
                        />
                        <DatePicker
                          holiday
                          value={values.endDate}
                          handleChange={(e) =>
                            this.setDate(
                              "endDate",
                              e,
                              setFieldValue,
                              values.startDate
                            )
                          }
                          tabIndex="3"
                          className={
                            (errors.endDate && touched.endDate) || serror
                              ? "empty"
                              : ""
                          }
                        />
                        {(errors.endDate && touched.endDate) || serror ? (
                          <div className="form-error">
                            {!serror ? errors.endDate : PageConst.mdDate}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.securityNo}
                          className={
                            errors.securityNo && touched.securityNo
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          name="securityNo"
                          value={values.securityNo}
                          onBlur={handleBlur}
                          tabIndex="4"
                          min="1"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "securityNo", val)
                          }
                          className={
                            errors.securityNo && touched.securityNo
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.pdm} />
                        <Input type="number"name="pDateMax"value={values.pDateMax}onBlur={handleBlur}
                          tabIndex="5"handleChange={(val) =>this.chnageNum(setFieldValue, "pDateMax", val)}
                          className={errors.pDateMax && touched.pDateMax ? "empty" : ""}/>
                      </Col> */}
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.ptp}
                          className={
                            errors.transPrice && touched.transPrice
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          type="number"
                          min="1"
                          name="transPrice"
                          value={values.transPrice}
                          onBlur={handleBlur}
                          tabIndex="6"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "transPrice", val)
                          }
                          className={
                            errors.transPrice && touched.transPrice
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label title={PageConst.cmp} />
                        <Input
                          type="number"
                          min="1"
                          name="currentPrice"
                          value={values.currentPrice}
                          onBlur={handleBlur}
                          tabIndex="7"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "currentPrice", val)
                          }
                          className={
                            errors.currentPrice && touched.currentPrice
                              ? "empty"
                              : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                        <Label
                          title={PageConst.tv}
                          className={
                            errors.mobile && touched.aname ? "empty" : ""
                          }
                        />
                        <div className="flex">
                          <Checkbox
                            onChange={() => this.onChange(false)}
                            checked={!checked}
                            text={PageConst.sm}
                          />
                          <Checkbox
                            onChange={() => this.onChange(true)}
                            checked={checked}
                            text={PageConst.of}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Label title={PageConst.frm} />
                        <a
                          className="abtn"
                          download={new Date().getTime()}
                          href={form}
                        >
                          <DownloadOutlined />
                          {PageConst.dwn}
                        </a>
                        <FileUpload
                          nm={PageConst.up}
                          accept=".pdf" // accept="image/*".docx,
                          image
                          file={docByte}
                          name={docnm}
                          sendByte={this.seByte}
                          tabIndex="8"
                        />
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.master.loading,
  loading1: state.trading.loading,
  td: state.trading.tradingData,
  closureList: state.closure.closureList,
  masterList: state.master.masterList,
  userList: state.user.userList,
  connRefList: state.connref.connRefList,
  holdinguData: state.holding.holdinguData,
  tradingList: state.trading.tradingList,
  templateList: state.template.templateList,
});
const mapDispatchToProps = (dispatch) => ({
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  addTrading: (payload) => dispatch(addTrading(payload)),
  getClosureList: (payload) => dispatch(getClosureList(payload)),
  updateTrading: (payload) => dispatch(updateTrading(payload)),
  getTradingById: (payload) => dispatch(getTradingById(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
  getHoldingByuId: (payload) => dispatch(getHoldingByuId(payload)),
  sendmail: (payload) => dispatch(sendmail(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTrading)
);
