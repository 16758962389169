export const GET_USERS_INITIATED = "GET_USERS_INITIATED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const GET_USER_BY_ID_INITIATED = "GET_USER_BY_ID_INITIATED";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR";

export const GET_USER_BY_DP_INITIATED = "GET_USER_BY_DP_INITIATED";
export const GET_USER_BY_DP_SUCCESS = "GET_USER_BY_DP_SUCCESS";
export const GET_USER_BY_DP_ERROR = "GET_USER_BY_DP_ERROR";

export const ADD_USER_INITIATED = "ADD_USER_INITIATED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER_INITIATED = "UPDATE_USER_INITIATED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_PWD_USER_INITIATED = "UPDATE_PWD_USER_INITIATED";
export const UPDATE_PWD_USER_SUCCESS = "UPDATE_PWD_USER_SUCCESS";
export const UPDATE_PWD_USER_ERROR = "UPDATE_PWD_USER_ERROR";

export const DELETE_USER_INITIATED = "DELETE_USER_INITIATED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";