export const GET_DASHBOARD_LIST_INITIATED = "GET_DASHBOARD_LIST_INITIATED";
export const GET_DASHBOARD_LIST_SUCCESS = "GET_DASHBOARD_LIST_SUCCESS";
export const GET_DASHBOARD_LIST_ERROR = "GET_DASHBOARD_LIST_ERROR";

export const GET_DASHBOARD_GRAPH_INITIATED = "GET_DASHBOARD_GRAPH_INITIATED";
export const GET_DASHBOARD_GRAPH_SUCCESS = "GET_DASHBOARD_GRAPH_SUCCESS";
export const GET_DASHBOARD_GRAPH_ERROR = "GET_DASHBOARD_GRAPH_ERROR";

export const GET_DASHBOARD_CAL_INITIATED = "GET_DASHBOARD_CAL_INITIATED";
export const GET_DASHBOARD_CAL_SUCCESS = "GET_DASHBOARD_CAL_SUCCESS";
export const GET_DASHBOARD_CAL_ERROR = "GET_DASHBOARD_CAL_ERROR";

export const GET_DASHGRAPH_INITIATED = "GET_DASHGRAPH_INITIATED";
export const GET_DASHGRAPH_SUCCESS = "GET_DASHGRAPH_SUCCESS";
export const GET_DASHGRAPH_ERROR = "GET_DASHGRAPH_ERROR";

export const GET_DASHPGRAPH_INITIATED = "GET_DASHPGRAPH_INITIATED";
export const GET_DASHPGRAPH_SUCCESS = "GET_DASHPGRAPH_SUCCESS";
export const GET_DASHPGRAPH_ERROR = "GET_DASHPGRAPH_ERROR";

export const GET_DASHGRAPH_MP_INITIATED = "GET_DASHGRAPH_MP_INITIATED";
export const GET_DASHGRAPH_MP_SUCCESS = "GET_DASHGRAPH_MP_SUCCESS";
export const GET_DASHGRAPH_MP_ERROR = "GET_DASHGRAPH_MP_ERROR";

export const GET_DASHGRAPH_YP_INITIATED = "GET_DASHGRAPH_YP_INITIATED";
export const GET_DASHGRAPH_YP_SUCCESS = "GET_DASHGRAPH_YP_SUCCESS";
export const GET_DASHGRAPH_YP_ERROR = "GET_DASHGRAPH_YP_ERROR";

export const GET_DASHGRAPH_YU_INITIATED = "GET_DASHGRAPH_YU_INITIATED";
export const GET_DASHGRAPH_YU_SUCCESS = "GET_DASHGRAPH_YU_SUCCESS";
export const GET_DASHGRAPH_YU_ERROR = "GET_DASHGRAPH_YU_ERROR";

export const GET_DASHGRAPH_MU_INITIATED = "GET_DASHGRAPH_MU_INITIATED";
export const GET_DASHGRAPH_MU_SUCCESS = "GET_DASHGRAPH_MU_SUCCESS";
export const GET_DASHGRAPH_MU_ERROR = "GET_DASHGRAPH_MU_ERROR";