import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { getMasterList } from "redux/master/actions";
import { ButtonConst, FormValid, panConst } from "App/AppConstant";
import {
  getUserList,
  getUserById,
  addUser,
  updateUser,
} from "redux/user/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
} from "components/Form";
import { genderList } from "container/AddUser/constant";
import { getAuthUserID, getAuthRole } from "modules/helper";
var userId = getAuthUserID();
var role = getAuthRole();
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  surname: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  fatherName: Yup.string()
    .trim()
    .nullable()
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  mobile: Yup.string()
    .nullable()
    .min(10)
    .max(10)
    .matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  email: Yup.string().trim().min(3).required(" ").email(FormValid.emailInvalid),
  gender: Yup.string().trim().nullable().required(" "),
  nationality: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  pan: Yup.string()
    .trim()
    .required(" ")
    .min(10)
    .max(10)
    .matches(panConst, FormValid.panValid),
  aadhar: Yup.string().trim().nullable().max(12).min(12),
  address: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ0-9.,()'-\s]+$/, FormValid.charValid),
  pincode: Yup.number().nullable().min(100000," ").max(999999," ")
    .required(" ").test("Is positive?", " ", (value) => value > 0), //.matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  city: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  state: Yup.string()
    .trim()
    .min(2)
    .max(50)
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  passport: Yup.string()
    .trim()
    .nullable()
    .matches(/^[aA-zZ\s]{1}[1-9]{1}[0-9]{5}[1-9]{1}$/, FormValid.passportValid),
  dpId: Yup.string()
    .trim()
    .max(8)
    .matches(/^[aA-zZ\s]{2}[0-9]{6}$/, FormValid.dpidValid),
  din: Yup.string()
    .trim()
    .nullable()
    .max(8)
    .min(8)
    .matches(/^[0-9\s]+$/, FormValid.charValid),
  // ifsc: Yup.string().trim().nullable().matches(ifscConst, FormValid.ifscValid),
  userName: Yup.string().trim().required(" ").matches(/^[a-zA-Z0-9]+[a-zA-Z0-9~!@#$%^&*()_+"';:,.?-]+$/, FormValid.charValid),
  // bankName: Yup.string().nullable().trim().matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  // branch: Yup.string().nullable().trim().matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  // bankAccount: Yup.string().nullable(),
});
class AddPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      disable: false,
      profile: false,
      uc: [],
      role: [],
      usrPan: [],
      usrlist: [],
      initBasic: {
        name: "",
        surname: "",
        fatherName: "",
        email: "",
        mobile: null,
        pan: "",
        gender: "",
        nationality: "",
        din: "",
        address: "",
        city: "",
        state: "",
        pincode: null,
        aadhar: null,
        passport: "",
        dpId: "",// bankAccount: null,bankName: "",ifsc: "",branch: "",
        userName: "",
        uid: 69,
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      if (match?.path?.toLowerCase().includes("/edit-connected-person/")) {
        await this.props.getUserById(window.atob(match?.params?.id));
        this.setState({ edit: true });
      } else if (match?.path?.toLowerCase().includes("/userprofile")) {
        await this.props.getUserById(userId);
        this.setState({ edit: true, profile: true, id: userId });
      }
      await this.props.getUserList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userData, userList, isSaved } = this.props;
      if (isSaved !== prevProps.isSaved) {
        isSaved && this.props.history.goBack();
      }
      if (userData !== prevProps.userData) {
        let cData = {
          id: userData.id,
          name: userData.name,
          surname: userData.surname,
          fatherName: userData.fatherName,
          email: userData.email,
          mobile: userData.mobile,
          pan: userData.pan,
          din: userData.din,
          gender: userData.gender,
          nationality: userData.nationality,
          dpId: userData.dpId,
          address: userData.address,
          city: userData.city,
          state: userData.state,
          pincode: userData.pincode,
          aadhar: userData.aadhar,
          passport: userData.passport,
          // bankAccount: userData.bankAccount,bankName: userData.bankName,ifsc: userData.ifsc,branch: userData.branch,
          userName: userData.userName,
        };
        this.setState({ initBasic: cData });
      }
      if (userList !== prevProps.userList) {
        let data = [],
          data2 = [];
        let urlist = userList.filter((a) => a.is_deleted === 0);
        urlist?.forEach((a) => {
          data2.push({ id: a.id, pan: a.pan });
          if (role === 67 && a.id !== userId) {
          } else {
            a.value = a.surname ? a.name + " " + a.surname : a.name;
            let pos = a.uid === 66 ? " (CO" : a.uid === 67 ? " (DP" : "";
            if (data.find((b) => b.value === a.value + pos + ")")) {
              if (data.find((b) => b.value === a.value + pos + ") 2")) {
                if (data.find((b) => b.value === a.value + pos + ") 3"))
                  data.push({ id: a.id, value: a.value + pos + ") 4" });
                else data.push({ id: a.id, value: a.value + pos + ") 3" });
              } else data.push({ id: a.id, value: a.value + pos + ") 2" });
            } else data.push({ id: a.id, value: a.value + pos + ")" });
          }
        });
        this.setState({ usrlist: data, usrPan: data2 });
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, name, data, setFieldValue, error) => {
    try {
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          showSearch={"No"}
          defaultValue={val}
          className={error ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue(name, value);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  setVal = (val) => (val?.toString()?.trim() !== "" ? val : null);
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { usrlist, usrPan } = this.state;
      values.pan = values.pan?.toUpperCase();
      let panInx = usrPan?.findIndex(
        (a) => a.pan?.toUpperCase() === values.pan
      );
      if (panInx > -1 && values.id)
        panInx = usrPan[panInx]?.id === values.id ? -1 : panInx;
      if (panInx > -1) message.error(PageConst.panErr);
      else {
        values.passport = values.passport?.toUpperCase();
        values.dpid = values.dpid?.toUpperCase();
        values.mobile = this.setVal(values.mobile);
        values.pincode = this.setVal(values.pincode);
        values.aadhar = this.setVal(values.aadhar);
        values.bankAccount = this.setVal(values.bankAccount); // .match(/\d+/g)?.join([]);
        if (values.id) this.props.updateUser(values);
        else {
          values.cid = userId;
          values.userName = values.email;
          values.dpName = usrlist?.find((a) => a.id === userId)?.value;
          this.props.addUser(values);
        }
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  back = () => this.props.history.push("/connected-person");
  chnageNum = (setFieldValue, name, e) => {
    let txt = e.target.value?.replace("e", "").replace(/-/, "");
    setFieldValue(name, txt);
  };
  render() {
    const { disable, initBasic, edit } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{edit ? PageConst.ed : PageConst.ad}</h4>
              <div className="bottomDiv">
                <div className="btn">
                  <Button onClick={() => this.back()}>
                    {ButtonConst.back}
                  </Button>
                </div>
              </div>
            </div>
            <Formik
              initialValues={initBasic}
              validationSchema={ValidationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <div className="display">
                    <h3 className="toptl">{PageConst.bsd}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.name}
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        <Input
                          name="name"
                          value={values.name?.trim()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="1"
                          className={errors.name && touched.name ? "empty" : ""}
                        />
                        {errors.name && touched.name ? (
                          <div className="form-error">{errors.name}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.surname}
                          className={
                            errors.surname && touched.surname ? "empty" : ""
                          }
                        />
                        <Input
                          name="surname"
                          value={values.surname?.trim()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          tabIndex="2"
                          className={
                            errors.surname && touched.surname ? "empty" : ""
                          }
                        />
                        {errors.surname && touched.surname ? (
                          <div className="form-error">{errors.surname}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.fatherName}
                          className={
                            errors.fatherName && touched.fatherName
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="fatherName"
                          value={values.fatherName?.trim()}
                          handleChange={handleChange}
                          tabIndex="3"
                          className={
                            errors.fatherName && touched.fatherName
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.fatherName && touched.fatherName ? (
                          <div className="form-error">{errors.fatherName}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.email}
                          className={
                            errors.email && touched.email ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="email"
                          value={values.email?.trim()}
                          handleChange={handleChange}
                          tabIndex="4"
                          className={
                            errors.email && touched.email ? "empty" : ""
                          }
                        />
                        {errors.email && touched.email ? (
                          <div className="form-error">{errors.email}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.mobile}
                          className={
                            errors.mobile && touched.mobile ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          type="number"
                          min="1"
                          name="mobile"
                          value={values.mobile}
                          tabIndex="5"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "mobile", val)
                          }
                          className={
                            errors.mobile && touched.mobile ? "empty" : ""
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.gender}
                          className={
                            errors.gender && touched.gender ? "empty" : ""
                          }
                        />
                        {values.gender === "" &&
                          this.selectUI(
                            values.gender,
                            "gender",
                            genderList,
                            setFieldValue,
                            errors.gender && touched.gender
                          )}
                        {values.gender !== "" &&
                          this.selectUI(
                            values.gender,
                            "gender",
                            genderList,
                            setFieldValue
                          )}
                      </Col>
                    </Row>
                  </div>
                  <div className="display">
                    <h3 className="toptl">{PageConst.ld}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.address}
                          className={
                            errors.address && touched.address ? "empty" : ""
                          }
                        />
                        <Input
                          row={4}
                          onBlur={handleBlur}
                          name="address"
                          value={values.address}
                          handleChange={handleChange}
                          tabIndex="6"
                          className={
                            errors.address && touched.address ? "empty" : ""
                          }
                        />
                        {errors.address && touched.address ? (
                          <div className="form-error">{errors.address}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.city}
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="city"
                          value={values.city}
                          tabIndex="7"
                          handleChange={handleChange}
                          className={errors.city && touched.city ? "empty" : ""}
                        />
                        {errors.city && touched.city ? (
                          <div className="form-error">{errors.city}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.state}
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="state"
                          value={values.state}
                          handleChange={handleChange}
                          tabIndex="8"
                          className={
                            errors.state && touched.state ? "empty" : ""
                          }
                        />
                        {errors.state && touched.state ? (
                          <div className="form-error">{errors.state}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pin}
                          className={
                            errors.pincode && touched.pincode ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="pincode"
                          type="number"
                          min="100000"
                          max="999999"
                          value={values.pincode}
                          tabIndex="9"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "pincode", val)
                          }
                          className={
                            errors.pincode && touched.pincode ? "empty" : ""
                          }
                        />
                        {errors.pincode && touched.pincode ? (
                          <div className="form-error">{errors.pincode}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div className="display">
                    <h3 className="toptl">{PageConst.pd}</h3>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.nation}
                          className={
                            errors.nationality && touched.nationality
                              ? "empty"
                              : ""
                          }
                        />
                        <Input
                          maxLength={50}
                          onBlur={handleBlur}
                          name="nationality"
                          value={values.nationality}
                          handleChange={handleChange}
                          tabIndex="10"
                          className={
                            errors.nationality && touched.nationality
                              ? "empty"
                              : ""
                          }
                        />
                        {errors.nationality && touched.nationality ? (
                          <div className="form-error">{errors.nationality}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.aadhar}
                          className={
                            errors.aadhar && touched.aadhar ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          type="number"
                          min="1"
                          name="aadhar"
                          value={values.aadhar}
                          tabIndex="11"
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "aadhar", val)
                          }
                          className={
                            errors.aadhar && touched.aadhar ? "empty" : ""
                          }
                        />
                        {errors.aadhar && touched.aadhar ? (
                          <div className="form-error">{errors.aadhar}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.pan}
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        <Input
                          name="pan"
                          value={values.pan?.toUpperCase()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="12"
                          className={errors.pan && touched.pan ? "empty" : ""}
                        />
                        {errors.pan && touched.pan ? (
                          <div className="form-error">{errors.pan}</div>
                        ) : null}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.din}
                          className={errors.din && touched.din ? "empty" : ""}
                        />
                        <Input
                          type="number"
                          name="din"
                          min="1"
                          value={values.din}
                          tabIndex="13"
                          onBlur={handleBlur}
                          handleChange={(val) =>
                            this.chnageNum(setFieldValue, "din", val)
                          }
                          className={errors.din && touched.din ? "empty" : ""}
                        />
                        {errors.din && touched.din && (
                          <div className="form-error">{errors.din}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.dpid}
                          className={errors.dpId && touched.dpId ? "empty" : ""}
                        />
                        <Input
                          name="dpId"
                          value={values.dpId?.toUpperCase()}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          tabIndex="14"
                          className={errors.dpId && touched.dpId ? "empty" : ""}
                        />
                        {errors.dpId && touched.dpId && (
                          <div className="form-error">{errors.dpId}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.passport}
                          className={
                            errors.passport && touched.passport ? "empty" : ""
                          }
                        />
                        <Input
                          onBlur={handleBlur}
                          name="passport"
                          value={values.passport?.toUpperCase()}
                          handleChange={handleChange}
                          tabIndex="15"
                          className={
                            errors.passport && touched.passport ? "empty" : ""
                          }
                        />
                        {errors.passport && touched.passport && (
                          <div className="form-error">{errors.passport}</div>
                        )}
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                      <Label title={PageConst.bank} className={errors.bankName && touched.bankName ? "empty" : ""}/>
                      <Input name="bankName" value={values.bankName}handleChange={handleChange}onBlur={handleBlur}
                        tabIndex="16"className={errors.bankName && touched.bankName ? "empty" : ""}/>
                      {errors.bankName && touched.bankName && (<div className="form-error">{errors.bankName}</div>)}</Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                      <Label title={PageConst.account}className={errors.bankAccount && touched.bankAccount? "empty": ""}/>
                      <Input type="number"name="bankAccount"value={values.bankAccount}tabIndex="17"
                      onBlur={handleBlur}handleChange={(val) =>this.chnageNum(setFieldValue, "bankAccount", val)}
                      className={errors.bankAccount && touched.bankAccount? "empty": ""}/>                      
                      {errors.bankAccount && touched.bankAccount && (<div className="form-error">{errors.bankAccount}</div>)}</Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                      <Label title={PageConst.ifsc}className={errors.ifsc && touched.ifsc ? "empty" : ""}/>
                      <Input onBlur={handleBlur}name="ifsc"value={values.ifsc}handleChange={handleChange}tabIndex="18"className={errors.ifsc && touched.ifsc ? "empty" : ""}/>
                      {errors.ifsc && touched.ifsc && (<div className="form-error">{errors.ifsc}</div>)}</Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}><Label title={PageConst.branch}className={errors.branch && touched.branch ? "empty" : ""}/>
                      <Input onBlur={handleBlur}name="branch"value={values.branch}handleChange={handleChange}tabIndex="19"className={errors.branch && touched.branch ? "empty" : ""}/>
                      {errors.branch && touched.branch && (<div className="form-error">{errors.branch}</div>)}</Col>*/
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                      <Label title={PageConst.un}className={errors.userName && touched.userName ? "empty" : ""}/>
                      <Input onBlur={handleBlur}name="userName"value={values.userName?.trim()}handleChange={handleChange}tabIndex="20"className={errors.userName && touched.userName ? "empty" : ""}/>
                    </Col> }
                    </Row>
                  </div>
                  <div className="btnDiv anime mt-1">
                    {role !== 67 && (
                      <Button onClick={() => this.back()}>
                        {ButtonConst.cancel}
                      </Button>
                    )}
                    <Button disabled={disable} htmlType={"submit"}>
                      {ButtonConst.save}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </LayOut>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  userData: state.user.userData,
  isSaved: state.user.isSaved,
  userList: state.user.userList,
  masterList: state.master.masterList,
});
const mapDispatchToProps = (dispatch) => ({
  addUser: (payload) => dispatch(addUser(payload)),
  updateUser: (payload) => dispatch(updateUser(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getUserById: (payload) => dispatch(getUserById(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPerson)
);
