import { message } from "antd";

import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { TemplatesConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getTemplateInfoList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_TEMPLATE_LIST_INITIATED });
    let response = await axiosAuthGet(TemplatesConst.GET_TEMPLATE_LIST);
    if (response?.success) await dispatch({ type: actions.GET_TEMPLATE_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_TEMPLATE_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_TEMPLATE_LIST_ERROR, error: "Network Error" });
  }
};

export const getTemplateInfoById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_TEMPLATE_BY_ID_INITIATED });
    let response = await axiosAuthGet(TemplatesConst.GET_TEMPLATE_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_TEMPLATE_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_TEMPLATE_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_TEMPLATE_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addTemplateInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_TEMPLATE_INITIATED });
    let response = await axiosAuthPost(TemplatesConst.ADD_TEMPLATE, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: "Template added" });
      await dispatch({ type: actions.ADD_TEMPLATE_SUCCESS, payload: response });
    } else dispatch({ type: actions.ADD_TEMPLATE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_TEMPLATE_ERROR, error: "Network Error" });
  }
};

export const updateTemplateInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TEMPLATE_INITIATED });
    let response = await axiosAuthPost(TemplatesConst.UPDATE_TEMPLATE + payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "Template updated" });
      await dispatch({ type: actions.UPDATE_TEMPLATE_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_TEMPLATE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_TEMPLATE_ERROR, error: "Network Error" });
  }
};

export const deleteTemplateInfo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_TEMPLATE_INITIATED });
    let response = await axiosAuthGet(TemplatesConst.DELETE_TEMPLATE + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "Template deleted" });
      await dispatch({ type: actions.DELETE_TEMPLATE_SUCCESS, payload: response });
      message.success(response?.message)
    } else dispatch({ type: actions.DELETE_TEMPLATE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_TEMPLATE_ERROR, error: "Network Error" });
  }
};
