import * as actions from "./constant";
import { axiosAuthGet } from "modules/Axios";
import { AuditConst } from "modules/config";

  export const getAuditLog = () => async (dispatch) => {
    try {
      dispatch({ type: actions.GET_AUDIT_INITIATED });
      let response = await axiosAuthGet(AuditConst.GET_AUDIT_LIST);
      if (response?.success)
        await dispatch({
          type: actions.GET_AUDIT_SUCCESS,
          payload: response,
        });
      else
        dispatch({
          type: actions.GET_AUDIT_ERROR,
          error: response?.message,
        });
    } catch (error) {
      console.log(error, "action catch");
      dispatch({
        type: actions.GET_AUDIT_ERROR,
        error: "Network Error",
      });
    }
  };
  